import { z } from 'zod'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './objects/VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldCreateInputObjectSchema } from './objects/VariantOnCustomFieldCreateInput.schema'
import { VariantOnCustomFieldUncheckedCreateInputObjectSchema } from './objects/VariantOnCustomFieldUncheckedCreateInput.schema'
import { VariantOnCustomFieldUpdateInputObjectSchema } from './objects/VariantOnCustomFieldUpdateInput.schema'
import { VariantOnCustomFieldUncheckedUpdateInputObjectSchema } from './objects/VariantOnCustomFieldUncheckedUpdateInput.schema'

export const VariantOnCustomFieldUpsertSchema = z.object({
  where: VariantOnCustomFieldWhereUniqueInputObjectSchema,
  create: z.union([
    VariantOnCustomFieldCreateInputObjectSchema,
    VariantOnCustomFieldUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    VariantOnCustomFieldUpdateInputObjectSchema,
    VariantOnCustomFieldUncheckedUpdateInputObjectSchema,
  ]),
})
