import { z } from 'zod'
import { VariantOnCustomFieldWhereInputObjectSchema } from './objects/VariantOnCustomFieldWhereInput.schema'
import { VariantOnCustomFieldOrderByWithAggregationInputObjectSchema } from './objects/VariantOnCustomFieldOrderByWithAggregationInput.schema'
import { VariantOnCustomFieldScalarWhereWithAggregatesInputObjectSchema } from './objects/VariantOnCustomFieldScalarWhereWithAggregatesInput.schema'
import { VariantOnCustomFieldScalarFieldEnumSchema } from './enums/VariantOnCustomFieldScalarFieldEnum.schema'

export const VariantOnCustomFieldGroupBySchema = z.object({
  where: VariantOnCustomFieldWhereInputObjectSchema.optional(),
  orderBy: z.union([
    VariantOnCustomFieldOrderByWithAggregationInputObjectSchema,
    VariantOnCustomFieldOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    VariantOnCustomFieldScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(VariantOnCustomFieldScalarFieldEnumSchema),
})
