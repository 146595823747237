import { z } from 'zod'
import { CustomFieldSelectObjectSchema } from './CustomFieldSelect.schema'
import { CustomFieldIncludeObjectSchema } from './CustomFieldInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldArgs> = z
  .object({
    select: z.lazy(() => CustomFieldSelectObjectSchema).optional(),
    include: z.lazy(() => CustomFieldIncludeObjectSchema).optional(),
  })
  .strict()

export const CustomFieldArgsObjectSchema = Schema
