import { z } from 'zod'
import { CustomFieldWhereUniqueInputObjectSchema } from './CustomFieldWhereUniqueInput.schema'
import { CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedCreateWithoutVariantCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldCreateOrConnectWithoutVariantCustomFieldsInput> =
  z
    .object({
      where: z.lazy(() => CustomFieldWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CustomFieldCreateOrConnectWithoutVariantCustomFieldsInputObjectSchema =
  Schema
