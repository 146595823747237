import { z } from 'zod'
import { VariantOnCustomFieldCreateManyVariantInputObjectSchema } from './VariantOnCustomFieldCreateManyVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateManyVariantInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => VariantOnCustomFieldCreateManyVariantInputObjectSchema),
        z
          .lazy(() => VariantOnCustomFieldCreateManyVariantInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const VariantOnCustomFieldCreateManyVariantInputEnvelopeObjectSchema =
  Schema
