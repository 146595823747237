import { z } from 'zod'

export const CustomFieldTypeSchema = z.enum([
  'AUXILIARY_LABEL',
  'DRUG_CLASSIFICATION',
  'PRODUCT_FORMS',
  'SKIN_TYPE',
  'CONCERNS',
  'ATTRIBUTES',
  'PRODUCT_USAGE',
  'PRODUCT_USAGE_UNIT',
  'PRODUCT_USAGE_FREQUENCY',
  'VARIANT_UNIT',
  'VARIANT_SELLING_UNIT',
])
