import { z } from 'zod'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { NestedEnumUseFrequencyNullableFilterObjectSchema } from './NestedEnumUseFrequencyNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumUseFrequencyNullableFilter> = z
  .object({
    equals: z
      .lazy(() => UseFrequencySchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UseFrequencySchema),
        z.lazy(() => NestedEnumUseFrequencyNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumUseFrequencyNullableFilterObjectSchema = Schema
