import { z } from 'zod'

export const SuggestedProductItemOriginalScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'productVariantId',
  'amount',
  'usage',
  'useAmount',
  'useUnit',
  'useFrequency',
  'useTime',
  'useDuration',
  'useDurationUnit',
  'refillTime',
  'refillTimeUnit',
  'note',
  'caseSummaryId',
  'warehouse',
  'customFields',
])
