import { z } from 'zod'
import { CustomFieldWhereUniqueInputObjectSchema } from './CustomFieldWhereUniqueInput.schema'
import { CustomFieldCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldCreateWithoutProductCustomFieldInput.schema'
import { CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUncheckedCreateWithoutProductCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldCreateOrConnectWithoutProductCustomFieldInput> =
  z
    .object({
      where: z.lazy(() => CustomFieldWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CustomFieldCreateWithoutProductCustomFieldInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CustomFieldCreateOrConnectWithoutProductCustomFieldInputObjectSchema =
  Schema
