import { z } from 'zod'
import { CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldCreateOrConnectWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateOrConnectWithoutVariantCustomFieldsInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './CustomFieldWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldCreateNestedOneWithoutVariantCustomFieldsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CustomFieldCreateOrConnectWithoutVariantCustomFieldsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CustomFieldWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CustomFieldCreateNestedOneWithoutVariantCustomFieldsInputObjectSchema =
  Schema
