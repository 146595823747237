import { z } from 'zod'
import { VariantCreateNestedOneWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateNestedOneWithoutVariantOnCustomFieldsInput.schema'
import { CustomFieldCreateNestedOneWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateNestedOneWithoutVariantCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateInput> = z
  .object({
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutVariantOnCustomFieldsInputObjectSchema,
    ),
    field: z.lazy(
      () =>
        CustomFieldCreateNestedOneWithoutVariantCustomFieldsInputObjectSchema,
    ),
  })
  .strict()

export const VariantOnCustomFieldCreateInputObjectSchema = Schema
