import { z } from 'zod'
import {
  CustomFieldModel,
  ProductModelResponse,
  VariantModelResponse,
  WarehouseModel,
} from '../products'
import { PictureModel } from './picture.model'
import {
  ProductResponseModel,
  ProductSKUPictureSchema,
  ProductVariantResponseModel,
} from './product.model'
import { ProductCustomFieldsModel } from './productCustomField.model'

export const ProductVariantModel = VariantModelResponse.omit({
  cost: true,
}).merge(
  z.object({
    pictures: PictureModel.nullable(),
    product: ProductModelResponse,
  }),
)

export const ProductVariantCustomFieldsModel = z.object({
  variantUnit: z.array(CustomFieldModel).nullable(),
  variantSellingUnit: z.array(CustomFieldModel).nullable(),
})
export type ProductVariantCustomFields = z.infer<
  typeof ProductVariantCustomFieldsModel
>

export const ProductVariantResponseWithRelatedFieldModel =
  ProductVariantResponseModel.extend({
    product: ProductResponseModel,
    customFields: ProductVariantCustomFieldsModel,
    warehouses: WarehouseModel.array(),
    pictures: z.array(ProductSKUPictureSchema),
  })
export type ProductVariantResponseWithRelatedField = z.infer<
  typeof ProductVariantResponseWithRelatedFieldModel
>

export const ProductVariantResponseWithRelatedFieldAndProductCustomFieldModel =
  ProductVariantResponseWithRelatedFieldModel.omit({ product: true }).extend({
    product: ProductResponseModel.omit({
      auxiliaryLabel: true,
      productForms: true,
      skinType: true,
      concerns: true,
      attributes: true,
      usage: true,
      useUnit: true,
      useFrequency: true,
    }).extend({
      customFields: ProductCustomFieldsModel,
    }),
  })
export type ProductVariantResponseWithRelatedFieldAndProductCustomField =
  z.infer<
    typeof ProductVariantResponseWithRelatedFieldAndProductCustomFieldModel
  >

export const ProductVariantWithCustomFieldModel = ProductVariantModel.omit({
  sellingUnit: true,
  unit: true,
  product: true,
}).merge(
  z.object({
    product:
      ProductVariantResponseWithRelatedFieldAndProductCustomFieldModel.shape
        .product,
    customFields: z.object({
      variantUnit: z.array(CustomFieldModel).nullable(),
      variantSellingUnit: z.array(CustomFieldModel).nullable(),
    }),
  }),
)
export type ProductVariantWithCustomField = z.infer<
  typeof ProductVariantWithCustomFieldModel
>

// mapped custom fields for App
export const ProductVariantMappedResponseWithRelatedFieldModel =
  ProductVariantResponseModel.extend({
    product: ProductResponseModel.omit({
      auxiliaryLabel: true,
      productForms: true,
      skinType: true,
      concerns: true,
      attributes: true,
      usage: true,
      useUnit: true,
      useFrequency: true,
    }).extend({
      customFields: z.object({
        auxiliaryLabel: CustomFieldModel.nullable(),
        drugClassification: CustomFieldModel.nullable(),
        productForms: z.array(CustomFieldModel).nullable(),
        skinType: z.array(CustomFieldModel).nullable(),
        concerns: z.array(CustomFieldModel).nullable(),
        attributes: CustomFieldModel.nullable(),
        productUsage: CustomFieldModel.nullable(),
        productUsageUnit: CustomFieldModel.nullable(),
        productUsageFrequency: CustomFieldModel.nullable(),
      }),
    }),
    // TODO<Fluke>: create base mapped customFields model that app use
    customFields: z.object({
      variantUnit: CustomFieldModel.nullable(),
      variantSellingUnit: CustomFieldModel.nullable(),
    }),
    warehouses: WarehouseModel.array(),
    pictures: z.array(ProductSKUPictureSchema),
  })

export type ProductVariantMappedResponseWithRelatedField = z.infer<
  typeof ProductVariantMappedResponseWithRelatedFieldModel
>
