import { z } from 'zod'
import { VariantWarehouseUncheckedCreateNestedManyWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedCreateNestedManyWithoutWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    name: z.string(),
    code: z.string(),
    active: z.boolean().optional(),
    variantWarehouses: z
      .lazy(
        () =>
          VariantWarehouseUncheckedCreateNestedManyWithoutWarehouseInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const WarehouseUncheckedCreateInputObjectSchema = Schema
