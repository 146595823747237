import { z } from 'zod'
import { VariantOnCustomFieldScalarWhereInputObjectSchema } from './VariantOnCustomFieldScalarWhereInput.schema'
import { VariantOnCustomFieldUpdateManyMutationInputObjectSchema } from './VariantOnCustomFieldUpdateManyMutationInput.schema'
import { VariantOnCustomFieldUncheckedUpdateManyWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantOnCustomFieldUncheckedUpdateManyWithoutVariantOnCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantOnCustomFieldScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantOnCustomFieldUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            VariantOnCustomFieldUncheckedUpdateManyWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInputObjectSchema =
  Schema
