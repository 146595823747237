import { z } from 'zod'
import { VariantUpdateOneRequiredWithoutVariantOnCustomFieldsNestedInputObjectSchema } from './VariantUpdateOneRequiredWithoutVariantOnCustomFieldsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateWithoutFieldInput> = z
  .object({
    variant: z
      .lazy(
        () =>
          VariantUpdateOneRequiredWithoutVariantOnCustomFieldsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantOnCustomFieldUpdateWithoutFieldInputObjectSchema = Schema
