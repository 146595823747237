import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    productId: z.literal(true).optional(),
    size: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    inventory: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
    rank: z.literal(true).optional(),
  })
  .strict()

export const VariantAvgAggregateInputObjectSchema = Schema
