import * as z from "zod"
import { CompleteProduct, RelatedProductModel, CompleteCustomField, RelatedCustomFieldModel } from "./index"

export const ProductCustomFieldModel = z.object({
  id: z.number().int(),
  productId: z.number().int(),
  fieldId: z.number().int(),
})

export interface CompleteProductCustomField extends z.infer<typeof ProductCustomFieldModel> {
  product: CompleteProduct
  field: CompleteCustomField
}

/**
 * RelatedProductCustomFieldModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductCustomFieldModel: z.ZodSchema<CompleteProductCustomField> = z.lazy(() => ProductCustomFieldModel.extend({
  product: RelatedProductModel,
  field: RelatedCustomFieldModel,
}))
