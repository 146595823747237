import { z } from 'zod'
import { VariantOnCustomFieldOrderByWithRelationInputObjectSchema } from './objects/VariantOnCustomFieldOrderByWithRelationInput.schema'
import { VariantOnCustomFieldWhereInputObjectSchema } from './objects/VariantOnCustomFieldWhereInput.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './objects/VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldScalarFieldEnumSchema } from './enums/VariantOnCustomFieldScalarFieldEnum.schema'

export const VariantOnCustomFieldFindManySchema = z.object({
  orderBy: z
    .union([
      VariantOnCustomFieldOrderByWithRelationInputObjectSchema,
      VariantOnCustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantOnCustomFieldWhereInputObjectSchema.optional(),
  cursor: VariantOnCustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(VariantOnCustomFieldScalarFieldEnumSchema).optional(),
})
