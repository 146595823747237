import { z } from 'zod'

export const CustomFieldScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'fieldType',
  'priority',
  'fieldOptionsTr',
  'active',
  'searchField',
])
