import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateManyVariantInput> = z
  .object({
    id: z.number().optional(),
    fieldId: z.number(),
  })
  .strict()

export const VariantOnCustomFieldCreateManyVariantInputObjectSchema = Schema
