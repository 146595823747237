import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    variantId: z.literal(true).optional(),
    fieldId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const VariantOnCustomFieldCountAggregateInputObjectSchema = Schema
