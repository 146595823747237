import { z } from 'zod'
import { ProductUpdateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUpdateWithoutProductOnCustomFieldInput.schema'
import { ProductUncheckedUpdateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUncheckedUpdateWithoutProductOnCustomFieldInput.schema'
import { ProductCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateWithoutProductOnCustomFieldInput.schema'
import { ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUncheckedCreateWithoutProductOnCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutProductOnCustomFieldInput> =
  z
    .object({
      update: z.union([
        z.lazy(() => ProductUpdateWithoutProductOnCustomFieldInputObjectSchema),
        z.lazy(
          () =>
            ProductUncheckedUpdateWithoutProductOnCustomFieldInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCreateWithoutProductOnCustomFieldInputObjectSchema),
        z.lazy(
          () =>
            ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductUpsertWithoutProductOnCustomFieldInputObjectSchema = Schema
