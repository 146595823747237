import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { SymptomDurationUnitSchema } from '@ssch-backend/teleconsults'
import { FC } from 'react'
import { required, useLocaleState } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { getSymptomDurationUnitLabel } from '../../../enumLabel'

interface CaseDetailProps {
  disabled?: boolean
}
export const CaseDetail: FC<CaseDetailProps> = ({ disabled = false }) => {
  const [locale] = useLocaleState()
  const { watch, setValue } = useFormContext()

  const congenitalDisease = watch('case.congenitalDisease')
  const drugAllergy = watch('case.drugAllergy')
  const symptomDuration = watch('case.symptomDuration')

  const hasDisease =
    congenitalDisease || congenitalDisease === '' ? 'have' : 'dontHave'
  const hasDrugAllergy = drugAllergy || drugAllergy === '' ? 'have' : 'dontHave'

  const handleRadioChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value === 'have') {
        setValue(field, '', { shouldDirty: true })
      } else {
        setValue(field, null, { shouldDirty: true })
      }
    }

  const handleInputChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(field, e.target.value, { shouldDirty: true })
    }

  return (
    <Box sx={{ mb: 3, width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Case Detail
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="อาการ"
            source="case.symptom"
            multiline
            rows={3}
            validate={required()}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <Grid item>
              <Typography sx={{ fontSize: '12px', mt: 1 }}>
                ประวัติการแพ้ยา/อาหาร*
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <RadioGroup
                  row
                  value={hasDrugAllergy}
                  onChange={handleRadioChange('case.drugAllergy')}
                  aria-labelledby="drug-allergy-radio-group"
                  name="drug-allergy-radio-group"
                >
                  <FormControlLabel
                    value="dontHave"
                    control={<Radio />}
                    label="ไม่มี"
                  />
                  <FormControlLabel
                    value="have"
                    control={<Radio />}
                    label="มี"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <FormTextInput
              label="ประวัติการแพ้ยา/อาหาร"
              multiline
              rows={3}
              source="case.drugAllergy"
              validate={hasDrugAllergy === 'have' ? required() : undefined}
              disabled={disabled || hasDrugAllergy === 'dontHave'}
              defaultValue={symptomDuration ? null : ''}
              value={drugAllergy}
              onChange={handleInputChange('case.drugAllergy')}
              sx={{
                flex: '0 0 auto',
                width: '390px',
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                  padding: '12px 8px',
                  lineHeight: '1.5',
                },
                '& .MuiInputBase-root': {
                  height: '100px',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormNumberInput
              label="มีอาการมาแล้ว (จำนวน)"
              source="case.symptomDuration"
              sx={{ flexGrow: 1 }}
              validate={required()}
              disabled={disabled}
            />
            <FormSelectInput
              label="มีอาการมาแล้ว (หน่วย)"
              source="case.symptomDurationUnit"
              choices={Object.keys(SymptomDurationUnitSchema.Enum).map(
                (key: unknown) => {
                  return {
                    id: key,
                    name: getSymptomDurationUnitLabel(
                      key as keyof typeof SymptomDurationUnitSchema.Enum,
                    )[locale === 'th' ? 'th' : 'en'],
                  }
                },
              )}
              validate={required()}
              disabled={disabled}
              defaultValue="DAY"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <Grid item>
              <Typography sx={{ fontSize: '12px', mt: 1 }}>
                โรคประจำตัว*
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <RadioGroup
                  row
                  value={hasDisease}
                  onChange={handleRadioChange('case.congenitalDisease')}
                  aria-labelledby="congenital-disease-radio-group"
                  name="congenital-disease-radio-group"
                >
                  <FormControlLabel
                    value="dontHave"
                    control={<Radio />}
                    label="ไม่มี"
                  />
                  <FormControlLabel
                    value="have"
                    control={<Radio />}
                    label="มี"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <FormTextInput
              label="โรคประจำตัว"
              multiline
              rows={3}
              source="case.congenitalDisease"
              validate={hasDisease === 'have' ? required() : undefined}
              disabled={disabled || hasDisease === 'dontHave'}
              defaultValue={symptomDuration ? null : ''}
              value={congenitalDisease || ''}
              onChange={handleInputChange('case.congenitalDisease')}
              sx={{
                flex: '0 0 auto',
                width: '390px',
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                  padding: '12px 8px',
                  lineHeight: '1.5',
                },
                '& .MuiInputBase-root': {
                  height: '100px',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
