import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithoutFieldInputObjectSchema } from './ProductCustomFieldUpdateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedUpdateWithoutFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductCustomFieldUpdateWithoutFieldInputObjectSchema),
        z.lazy(
          () => ProductCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema =
  Schema
