import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantOrderByWithRelationInputObjectSchema } from './VariantOrderByWithRelationInput.schema'
import { CustomFieldOrderByWithRelationInputObjectSchema } from './CustomFieldOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    fieldId: z.lazy(() => SortOrderSchema).optional(),
    variant: z
      .lazy(() => VariantOrderByWithRelationInputObjectSchema)
      .optional(),
    field: z
      .lazy(() => CustomFieldOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const VariantOnCustomFieldOrderByWithRelationInputObjectSchema = Schema
