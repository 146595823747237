import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { EnumUnitNullableFilterObjectSchema } from './EnumUnitNullableFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => VariantScalarWhereInputObjectSchema),
        z.lazy(() => VariantScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => VariantScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => VariantScalarWhereInputObjectSchema),
        z.lazy(() => VariantScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    sku: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    productId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    labelTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    batchAndExpireDate: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    size: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    cost: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    tagPrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    inventory: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    canExpress: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    v2DrugItemId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    inventoryUpdatedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    unit: z
      .union([
        z.lazy(() => EnumUnitNullableFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional()
      .nullable(),
    sellingUnit: z
      .union([
        z.lazy(() => EnumUnitNullableFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional()
      .nullable(),
    descriptionTr: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
    rank: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
  })
  .strict()

export const VariantScalarWhereInputObjectSchema = Schema
