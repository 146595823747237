import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCreateWithoutVariantWarehousesInput> = z
  .object({
    name: z.string(),
    code: z.string(),
    active: z.boolean().optional(),
  })
  .strict()

export const WarehouseCreateWithoutVariantWarehousesInputObjectSchema = Schema
