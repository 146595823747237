import { z } from 'zod'
import { ProductCustomFieldUpdateInputObjectSchema } from './objects/ProductCustomFieldUpdateInput.schema'
import { ProductCustomFieldUncheckedUpdateInputObjectSchema } from './objects/ProductCustomFieldUncheckedUpdateInput.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './objects/ProductCustomFieldWhereUniqueInput.schema'

export const ProductCustomFieldUpdateOneSchema = z.object({
  data: z.union([
    ProductCustomFieldUpdateInputObjectSchema,
    ProductCustomFieldUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductCustomFieldWhereUniqueInputObjectSchema,
})
