import { z } from 'zod'
import { VariantOnCustomFieldSelectObjectSchema } from './VariantOnCustomFieldSelect.schema'
import { VariantOnCustomFieldIncludeObjectSchema } from './VariantOnCustomFieldInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldArgs> = z
  .object({
    select: z.lazy(() => VariantOnCustomFieldSelectObjectSchema).optional(),
    include: z.lazy(() => VariantOnCustomFieldIncludeObjectSchema).optional(),
  })
  .strict()

export const VariantOnCustomFieldArgsObjectSchema = Schema
