import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductOrderByWithRelationInputObjectSchema } from './ProductOrderByWithRelationInput.schema'
import { CustomFieldOrderByWithRelationInputObjectSchema } from './CustomFieldOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    fieldId: z.lazy(() => SortOrderSchema).optional(),
    product: z
      .lazy(() => ProductOrderByWithRelationInputObjectSchema)
      .optional(),
    field: z
      .lazy(() => CustomFieldOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductCustomFieldOrderByWithRelationInputObjectSchema = Schema
