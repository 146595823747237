import { z } from 'zod'
import { CustomFieldWhereUniqueInputObjectSchema } from './objects/CustomFieldWhereUniqueInput.schema'
import { CustomFieldCreateInputObjectSchema } from './objects/CustomFieldCreateInput.schema'
import { CustomFieldUncheckedCreateInputObjectSchema } from './objects/CustomFieldUncheckedCreateInput.schema'
import { CustomFieldUpdateInputObjectSchema } from './objects/CustomFieldUpdateInput.schema'
import { CustomFieldUncheckedUpdateInputObjectSchema } from './objects/CustomFieldUncheckedUpdateInput.schema'

export const CustomFieldUpsertSchema = z.object({
  where: CustomFieldWhereUniqueInputObjectSchema,
  create: z.union([
    CustomFieldCreateInputObjectSchema,
    CustomFieldUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CustomFieldUpdateInputObjectSchema,
    CustomFieldUncheckedUpdateInputObjectSchema,
  ]),
})
