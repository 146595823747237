import { z } from 'zod'
import { ProductCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateWithoutProductOnCustomFieldInput.schema'
import { ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUncheckedCreateWithoutProductOnCustomFieldInput.schema'
import { ProductCreateOrConnectWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateOrConnectWithoutProductOnCustomFieldInput.schema'
import { ProductUpsertWithoutProductOnCustomFieldInputObjectSchema } from './ProductUpsertWithoutProductOnCustomFieldInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUpdateWithoutProductOnCustomFieldInput.schema'
import { ProductUncheckedUpdateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUncheckedUpdateWithoutProductOnCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutProductOnCustomFieldNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductCreateWithoutProductOnCustomFieldInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductCreateOrConnectWithoutProductOnCustomFieldInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutProductOnCustomFieldInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => ProductUpdateWithoutProductOnCustomFieldInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedUpdateWithoutProductOnCustomFieldInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutProductOnCustomFieldNestedInputObjectSchema =
  Schema
