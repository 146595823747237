import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteBrand, RelatedBrandModel, CompleteProductCategory, RelatedProductCategoryModel, CompleteVariant, RelatedVariantModel, CompleteProductPicture, RelatedProductPictureModel, CompleteProductVideo, RelatedProductVideoModel, CompleteProductReview, RelatedProductReviewModel, CompleteProductReviewSummary, RelatedProductReviewSummaryModel, CompleteProductCustomField, RelatedProductCustomFieldModel } from "./index"

export const ProductModel = z.object({
  id: z.number().int(),
  active: z.boolean(),
  searchable: z.boolean(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  searchField: z.string(),
  regNo: z.string(),
  brandId: z.number().int(),
  type: z.enum(['DRUG', 'SKINCARE', 'SUPPLEMENTS', 'TOOLS']),
  priority: z.number().int(),
  salesType: z.enum(['MERCHANDISE', 'FREEBIES']),
  drugGroup: z.enum(['MEDICAL', 'SPECIAL_MEDICAL']).nullish(),
  propertyTr: z.object({ en: z.string(), th: z.string() }),
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  sideEffectTr: z.object({ en: z.string(), th: z.string() }),
  cautionTr: z.object({ en: z.string(), th: z.string() }),
  genericTr: z.object({ en: z.string(), th: z.string() }),
  genericSearch: z.string(),
  useAmount: z.string().nullish(),
  instructionTr: z.object({ en: z.string(), th: z.string() }),
  pharmacyNoteTr: z.object({ en: z.string(), th: z.string() }),
  pharmacyNote: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  auxiliaryLabel: z.enum(['FOR_EXTERNAL_USE_ONLY', 'CONTINUE_THIS_MEDICATION_UNTIL_FINISHED', 'CONSULT_DOCTOR_OR_PHARMACIST_IF_PREGNANT_OR_BREASTFEEDING', 'AS_NEEDED', 'TAKE_AFTER_MEAL_IMMEDIATELY_DO_NOT_TAKE_ON_EMPTY_STOMACH', 'MAY_MAKE_YOU_FEEL_DROWSY', 'ONCE_MIXED_CAN_ONLY_BE_KEPT_7_DAYS', 'ONCE_MIXED_KEEP_IN_REFRIGERATOR_DO_NOT_FREEZE', 'USE_THIS_MEDICATION_ACCORDING_TO_DOCTOR_ADVICE', 'DO_NOT_TAKE_WITH_MILK_ANTACIDS_CALCIUM_IRON_ZINC_MAGNESIUM_MANGANESE', 'SEE_PACKAGE_INSERT_FOR_COMPLETE_INFORMATION', 'TAKE_AS_INSTRUCTED', 'THIS_MEDICINE_MUST_BE_STORED_AT_2_TO_8_CELSIUS', 'DO_NOT_DONATE_BLOOD_WHILE_TAKING_THIS_MEDICINE', 'MUST_USE_BIRTH_CONTROL_WHILE_TAKING_THIS_MEDICINE']).nullish(),
  productForms: jsonSchema,
  skinType: jsonSchema,
  concerns: jsonSchema,
  attributes: jsonSchema,
  usage: z.enum(['TAKE', 'APPLY', 'APPLY_ACNE', 'APPLY_DARK_SPOT', 'SPRAY', 'SHAMPOO', 'APPLY_AFFECTED_AREA_AND_LEAVE_FOR', 'EYE_DROPS', 'PUT_ON', 'CLEANSE_FACE', 'INSERT_VAGINA', 'APPLY_30_MIN_BEFORE_SUN', 'APPLY_PEA_SIZE', 'SHOWER', 'DISSOLVE_ROOM_TEMP_WATER', 'APPLY_LEAVE_FOR_AND_RINSE', 'SQUEEZE_IN_A_BRUSH', 'CLEAN', 'SLIGHTLY_DISSOLVED_WAIT_FOR_BUBBLE_THEN_DRINK', 'PUT_ON_HAIR', 'CLEAN_HIDDEN_SPOT', 'BRUSH', 'NA', 'PASTE', 'THROAT_SPRAY']).nullish(),
  useUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  useFrequency: z.enum(['ONCE_DAILY', 'ONCE_DAILY_AFTER_MEAL', 'ONCE_DAILY_BEFORE_MEAL', 'ONCE_DAILY_WITH_MEAL', 'TWICE_DAILY', 'TWICE_DAILY_AFTER_MEAL', 'TWICE_DAILY_BEFORE_MEAL', 'TWICE_DAILY_WITH_MEAL', 'THRICE_DAILY', 'THRICE_DAILY_AFTER_MEAL', 'THRICE_DAILY_BEFORE_MEAL', 'THRICE_DAILY_WITH_MEAL', 'FOUR_TIMES_DAILY', 'FOUR_TIMES_DAILY_AFTER_MEAL', 'FOUR_TIMES_DAILY_BEFORE_MEAL', 'FOUR_TIMES_DAILY_WITH_MEAL', 'FIVE_TIMES_DAILY', 'EVERY_OTHER_DAY', 'ONCE_A_WEEK', 'TWICE_A_WEEK', 'THRICE_A_WEEK', 'EVERY_4_TO_6_HR', 'EVERY_4_HR', 'EVERY_6_HR', 'EVERY_8_HR', 'EVERY_12_HR', 'EVERY_24_HR', 'NA']).nullish(),
  useTime: z.enum(['AS_NEED', 'MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME', 'MORNING_AND_EVENING', 'MORNING_AND_BEFORE_BEDTIME', 'NOON_AND_BEFORE_BEDTIME', 'MORNING_NOON_AND_EVENING', 'MORNING_NOON_EVENING_AND_BEFORE_BEDTIME', 'EVERY_MONDAY', 'EVERY_TUESDAY', 'EVERY_WEDNESDAY', 'EVERY_THURSDAY', 'EVERY_FRIDAY', 'EVERY_SATURDAY', 'EVERY_SUNDAY', 'TUESDAY_AND_THURSDAY', 'MONDAY_WEDNESDAY_AND_FRIDAY', 'ON_EVEN_DAY', 'ON_ODD_DAY', 'AT_6_10_14_18_22_HR', 'IMMEDIATELY', 'NA']).nullish(),
  ingredient: z.string().nullish(),
})

export const ProductModelResponse = z.object({
  id: z.number().int(),
  active: z.boolean(),
  searchable: z.boolean(),
  nameTr: jsonSchema,
  searchField: z.string(),
  regNo: z.string(),
  brandId: z.number().int(),
  type: z.enum(['DRUG', 'SKINCARE', 'SUPPLEMENTS', 'TOOLS']),
  priority: z.number().int(),
  salesType: z.enum(['MERCHANDISE', 'FREEBIES']),
  drugGroup: z.enum(['MEDICAL', 'SPECIAL_MEDICAL']).nullish(),
  propertyTr: jsonSchema,
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  descriptionTr: jsonSchema,
  sideEffectTr: jsonSchema,
  cautionTr: jsonSchema,
  genericTr: jsonSchema,
  genericSearch: z.string(),
  useAmount: z.string().nullish(),
  instructionTr: jsonSchema,
  pharmacyNoteTr: jsonSchema,
  pharmacyNote: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  auxiliaryLabel: z.enum(['FOR_EXTERNAL_USE_ONLY', 'CONTINUE_THIS_MEDICATION_UNTIL_FINISHED', 'CONSULT_DOCTOR_OR_PHARMACIST_IF_PREGNANT_OR_BREASTFEEDING', 'AS_NEEDED', 'TAKE_AFTER_MEAL_IMMEDIATELY_DO_NOT_TAKE_ON_EMPTY_STOMACH', 'MAY_MAKE_YOU_FEEL_DROWSY', 'ONCE_MIXED_CAN_ONLY_BE_KEPT_7_DAYS', 'ONCE_MIXED_KEEP_IN_REFRIGERATOR_DO_NOT_FREEZE', 'USE_THIS_MEDICATION_ACCORDING_TO_DOCTOR_ADVICE', 'DO_NOT_TAKE_WITH_MILK_ANTACIDS_CALCIUM_IRON_ZINC_MAGNESIUM_MANGANESE', 'SEE_PACKAGE_INSERT_FOR_COMPLETE_INFORMATION', 'TAKE_AS_INSTRUCTED', 'THIS_MEDICINE_MUST_BE_STORED_AT_2_TO_8_CELSIUS', 'DO_NOT_DONATE_BLOOD_WHILE_TAKING_THIS_MEDICINE', 'MUST_USE_BIRTH_CONTROL_WHILE_TAKING_THIS_MEDICINE']).nullish(),
  productForms: jsonSchema,
  skinType: jsonSchema,
  concerns: jsonSchema,
  attributes: jsonSchema,
  usage: z.enum(['TAKE', 'APPLY', 'APPLY_ACNE', 'APPLY_DARK_SPOT', 'SPRAY', 'SHAMPOO', 'APPLY_AFFECTED_AREA_AND_LEAVE_FOR', 'EYE_DROPS', 'PUT_ON', 'CLEANSE_FACE', 'INSERT_VAGINA', 'APPLY_30_MIN_BEFORE_SUN', 'APPLY_PEA_SIZE', 'SHOWER', 'DISSOLVE_ROOM_TEMP_WATER', 'APPLY_LEAVE_FOR_AND_RINSE', 'SQUEEZE_IN_A_BRUSH', 'CLEAN', 'SLIGHTLY_DISSOLVED_WAIT_FOR_BUBBLE_THEN_DRINK', 'PUT_ON_HAIR', 'CLEAN_HIDDEN_SPOT', 'BRUSH', 'NA', 'PASTE', 'THROAT_SPRAY']).nullish(),
  useUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  useFrequency: z.enum(['ONCE_DAILY', 'ONCE_DAILY_AFTER_MEAL', 'ONCE_DAILY_BEFORE_MEAL', 'ONCE_DAILY_WITH_MEAL', 'TWICE_DAILY', 'TWICE_DAILY_AFTER_MEAL', 'TWICE_DAILY_BEFORE_MEAL', 'TWICE_DAILY_WITH_MEAL', 'THRICE_DAILY', 'THRICE_DAILY_AFTER_MEAL', 'THRICE_DAILY_BEFORE_MEAL', 'THRICE_DAILY_WITH_MEAL', 'FOUR_TIMES_DAILY', 'FOUR_TIMES_DAILY_AFTER_MEAL', 'FOUR_TIMES_DAILY_BEFORE_MEAL', 'FOUR_TIMES_DAILY_WITH_MEAL', 'FIVE_TIMES_DAILY', 'EVERY_OTHER_DAY', 'ONCE_A_WEEK', 'TWICE_A_WEEK', 'THRICE_A_WEEK', 'EVERY_4_TO_6_HR', 'EVERY_4_HR', 'EVERY_6_HR', 'EVERY_8_HR', 'EVERY_12_HR', 'EVERY_24_HR', 'NA']).nullish(),
  useTime: z.enum(['AS_NEED', 'MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME', 'MORNING_AND_EVENING', 'MORNING_AND_BEFORE_BEDTIME', 'NOON_AND_BEFORE_BEDTIME', 'MORNING_NOON_AND_EVENING', 'MORNING_NOON_EVENING_AND_BEFORE_BEDTIME', 'EVERY_MONDAY', 'EVERY_TUESDAY', 'EVERY_WEDNESDAY', 'EVERY_THURSDAY', 'EVERY_FRIDAY', 'EVERY_SATURDAY', 'EVERY_SUNDAY', 'TUESDAY_AND_THURSDAY', 'MONDAY_WEDNESDAY_AND_FRIDAY', 'ON_EVEN_DAY', 'ON_ODD_DAY', 'AT_6_10_14_18_22_HR', 'IMMEDIATELY', 'NA']).nullish(),
  ingredient: z.string().nullish(),
})

export interface CompleteProduct extends z.infer<typeof ProductModel> {
  brand: CompleteBrand
  categories: CompleteProductCategory[]
  variants: CompleteVariant[]
  pictures: CompleteProductPicture[]
  video: CompleteProductVideo[]
  productReviews: CompleteProductReview[]
  productReviewSummary?: CompleteProductReviewSummary | null
  productOnCustomField: CompleteProductCustomField[]
}

/**
 * RelatedProductModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductModel: z.ZodSchema<CompleteProduct> = z.lazy(() => ProductModel.extend({
  brand: RelatedBrandModel,
  categories: RelatedProductCategoryModel.array(),
  variants: RelatedVariantModel.array(),
  pictures: RelatedProductPictureModel.array(),
  video: RelatedProductVideoModel.array(),
  productReviews: RelatedProductReviewModel.array(),
  productReviewSummary: RelatedProductReviewSummaryModel.nullish(),
  productOnCustomField: RelatedProductCustomFieldModel.array(),
}))
