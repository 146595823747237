import { z } from 'zod'
import { ProductCustomFieldOrderByWithRelationInputObjectSchema } from './objects/ProductCustomFieldOrderByWithRelationInput.schema'
import { ProductCustomFieldWhereInputObjectSchema } from './objects/ProductCustomFieldWhereInput.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './objects/ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldCountAggregateInputObjectSchema } from './objects/ProductCustomFieldCountAggregateInput.schema'
import { ProductCustomFieldMinAggregateInputObjectSchema } from './objects/ProductCustomFieldMinAggregateInput.schema'
import { ProductCustomFieldMaxAggregateInputObjectSchema } from './objects/ProductCustomFieldMaxAggregateInput.schema'
import { ProductCustomFieldAvgAggregateInputObjectSchema } from './objects/ProductCustomFieldAvgAggregateInput.schema'
import { ProductCustomFieldSumAggregateInputObjectSchema } from './objects/ProductCustomFieldSumAggregateInput.schema'

export const ProductCustomFieldAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductCustomFieldOrderByWithRelationInputObjectSchema,
      ProductCustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductCustomFieldWhereInputObjectSchema.optional(),
  cursor: ProductCustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductCustomFieldCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductCustomFieldMinAggregateInputObjectSchema.optional(),
  _max: ProductCustomFieldMaxAggregateInputObjectSchema.optional(),
  _avg: ProductCustomFieldAvgAggregateInputObjectSchema.optional(),
  _sum: ProductCustomFieldSumAggregateInputObjectSchema.optional(),
})
