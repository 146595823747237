import { z } from 'zod'
import { VariantOnCustomFieldCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldCreateWithoutFieldInput.schema'
import { VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUncheckedCreateWithoutFieldInput.schema'
import { VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema } from './VariantOnCustomFieldCreateOrConnectWithoutFieldInput.schema'
import { VariantOnCustomFieldCreateManyFieldInputEnvelopeObjectSchema } from './VariantOnCustomFieldCreateManyFieldInputEnvelope.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUncheckedCreateNestedManyWithoutFieldInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantOnCustomFieldCreateWithoutFieldInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldCreateWithoutFieldInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantOnCustomFieldCreateManyFieldInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldUncheckedCreateNestedManyWithoutFieldInputObjectSchema =
  Schema
