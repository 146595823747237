import { z } from 'zod'
import { VariantOnCustomFieldCreateInputObjectSchema } from './objects/VariantOnCustomFieldCreateInput.schema'
import { VariantOnCustomFieldUncheckedCreateInputObjectSchema } from './objects/VariantOnCustomFieldUncheckedCreateInput.schema'

export const VariantOnCustomFieldCreateOneSchema = z.object({
  data: z.union([
    VariantOnCustomFieldCreateInputObjectSchema,
    VariantOnCustomFieldUncheckedCreateInputObjectSchema,
  ]),
})
