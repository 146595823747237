import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldCreateOrConnectWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema),
        z.lazy(
          () => ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema =
  Schema
