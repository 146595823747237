import { z } from 'zod'
import { ProductCustomFieldOrderByWithRelationInputObjectSchema } from './objects/ProductCustomFieldOrderByWithRelationInput.schema'
import { ProductCustomFieldWhereInputObjectSchema } from './objects/ProductCustomFieldWhereInput.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './objects/ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldScalarFieldEnumSchema } from './enums/ProductCustomFieldScalarFieldEnum.schema'

export const ProductCustomFieldFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProductCustomFieldOrderByWithRelationInputObjectSchema,
      ProductCustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductCustomFieldWhereInputObjectSchema.optional(),
  cursor: ProductCustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductCustomFieldScalarFieldEnumSchema).optional(),
})
