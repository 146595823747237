import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { EnumUsageNullableWithAggregatesFilterObjectSchema } from './EnumUsageNullableWithAggregatesFilter.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'
import { EnumUnitNullableWithAggregatesFilterObjectSchema } from './EnumUnitNullableWithAggregatesFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { EnumUseFrequencyNullableWithAggregatesFilterObjectSchema } from './EnumUseFrequencyNullableWithAggregatesFilter.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseTimeNullableWithAggregatesFilterObjectSchema } from './EnumUseTimeNullableWithAggregatesFilter.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { IntNullableWithAggregatesFilterObjectSchema } from './IntNullableWithAggregatesFilter.schema'
import { EnumUseDurationUnitNullableWithAggregatesFilterObjectSchema } from './EnumUseDurationUnitNullableWithAggregatesFilter.schema'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'
import { EnumRefillTimeUnitNullableWithAggregatesFilterObjectSchema } from './EnumRefillTimeUnitNullableWithAggregatesFilter.schema'
import { RefillTimeUnitSchema } from '../enums/RefillTimeUnit.schema'
import { JsonNullableWithAggregatesFilterObjectSchema } from './JsonNullableWithAggregatesFilter.schema'
import { JsonWithAggregatesFilterObjectSchema } from './JsonWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalScalarWhereWithAggregatesInput> =
  z
    .object({
      AND: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      OR: z
        .lazy(
          () =>
            SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema,
        )
        .array()
        .optional(),
      NOT: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      id: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      createdAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      productVariantId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      amount: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      usage: z
        .union([
          z.lazy(() => EnumUsageNullableWithAggregatesFilterObjectSchema),
          z.lazy(() => UsageSchema),
        ])
        .optional()
        .nullable(),
      useAmount: z
        .union([
          z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
          z.string(),
        ])
        .optional()
        .nullable(),
      useUnit: z
        .union([
          z.lazy(() => EnumUnitNullableWithAggregatesFilterObjectSchema),
          z.lazy(() => UnitSchema),
        ])
        .optional()
        .nullable(),
      useFrequency: z
        .union([
          z.lazy(
            () => EnumUseFrequencyNullableWithAggregatesFilterObjectSchema,
          ),
          z.lazy(() => UseFrequencySchema),
        ])
        .optional()
        .nullable(),
      useTime: z
        .union([
          z.lazy(() => EnumUseTimeNullableWithAggregatesFilterObjectSchema),
          z.lazy(() => UseTimeSchema),
        ])
        .optional()
        .nullable(),
      useDuration: z
        .union([
          z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
          z.number(),
        ])
        .optional()
        .nullable(),
      useDurationUnit: z
        .union([
          z.lazy(
            () => EnumUseDurationUnitNullableWithAggregatesFilterObjectSchema,
          ),
          z.lazy(() => UseDurationUnitSchema),
        ])
        .optional()
        .nullable(),
      refillTime: z
        .union([
          z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
          z.number(),
        ])
        .optional()
        .nullable(),
      refillTimeUnit: z
        .union([
          z.lazy(
            () => EnumRefillTimeUnitNullableWithAggregatesFilterObjectSchema,
          ),
          z.lazy(() => RefillTimeUnitSchema),
        ])
        .optional()
        .nullable(),
      note: z
        .union([
          z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
          z.string(),
        ])
        .optional()
        .nullable(),
      caseSummaryId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      warehouse: z
        .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
        .optional(),
      customFields: z
        .lazy(() => JsonWithAggregatesFilterObjectSchema)
        .optional(),
    })
    .strict()

export const SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema =
  Schema
