import { z } from 'zod'
import { UsageSchema } from '../enums/Usage.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'
import { RefillTimeUnitSchema } from '../enums/RefillTimeUnit.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.SuggestedProductItemUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    caseSummaryId: z.number(),
    productVariantId: z.number(),
    amount: z.number(),
    usage: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
    useAmount: z.string().optional().nullable(),
    useUnit: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    useFrequency: z
      .lazy(() => UseFrequencySchema)
      .optional()
      .nullable(),
    useTime: z
      .lazy(() => UseTimeSchema)
      .optional()
      .nullable(),
    useDuration: z.number().optional().nullable(),
    useDurationUnit: z
      .lazy(() => UseDurationUnitSchema)
      .optional()
      .nullable(),
    refillTime: z.number().optional().nullable(),
    refillTimeUnit: z
      .lazy(() => RefillTimeUnitSchema)
      .optional()
      .nullable(),
    note: z.string().optional().nullable(),
    warehouse: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    customFields: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
  })
  .strict()

export const SuggestedProductItemUncheckedCreateInputObjectSchema = Schema
