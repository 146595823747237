import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantOnCustomFieldCountOrderByAggregateInputObjectSchema } from './VariantOnCustomFieldCountOrderByAggregateInput.schema'
import { VariantOnCustomFieldAvgOrderByAggregateInputObjectSchema } from './VariantOnCustomFieldAvgOrderByAggregateInput.schema'
import { VariantOnCustomFieldMaxOrderByAggregateInputObjectSchema } from './VariantOnCustomFieldMaxOrderByAggregateInput.schema'
import { VariantOnCustomFieldMinOrderByAggregateInputObjectSchema } from './VariantOnCustomFieldMinOrderByAggregateInput.schema'
import { VariantOnCustomFieldSumOrderByAggregateInputObjectSchema } from './VariantOnCustomFieldSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      variantId: z.lazy(() => SortOrderSchema).optional(),
      fieldId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => VariantOnCustomFieldCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => VariantOnCustomFieldAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => VariantOnCustomFieldMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => VariantOnCustomFieldMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => VariantOnCustomFieldSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldOrderByWithAggregationInputObjectSchema =
  Schema
