import { z } from 'zod'
import { BrandArgsObjectSchema } from './BrandArgs.schema'
import { ProductCategoryFindManySchema } from '../findManyProductCategory.schema'
import { VariantFindManySchema } from '../findManyVariant.schema'
import { ProductPictureFindManySchema } from '../findManyProductPicture.schema'
import { ProductVideoFindManySchema } from '../findManyProductVideo.schema'
import { ProductReviewFindManySchema } from '../findManyProductReview.schema'
import { ProductReviewSummaryArgsObjectSchema } from './ProductReviewSummaryArgs.schema'
import { ProductCustomFieldFindManySchema } from '../findManyProductCustomField.schema'
import { ProductCountOutputTypeArgsObjectSchema } from './ProductCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductInclude> = z
  .object({
    brand: z
      .union([z.boolean(), z.lazy(() => BrandArgsObjectSchema)])
      .optional(),
    categories: z
      .union([z.boolean(), z.lazy(() => ProductCategoryFindManySchema)])
      .optional(),
    variants: z
      .union([z.boolean(), z.lazy(() => VariantFindManySchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProductPictureFindManySchema)])
      .optional(),
    video: z
      .union([z.boolean(), z.lazy(() => ProductVideoFindManySchema)])
      .optional(),
    productReviews: z
      .union([z.boolean(), z.lazy(() => ProductReviewFindManySchema)])
      .optional(),
    productReviewSummary: z
      .union([z.boolean(), z.lazy(() => ProductReviewSummaryArgsObjectSchema)])
      .optional(),
    productOnCustomField: z
      .union([z.boolean(), z.lazy(() => ProductCustomFieldFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProductCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductIncludeObjectSchema = Schema
