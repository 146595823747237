import { z } from 'zod'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldUpdateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUpdateWithoutFieldInput.schema'
import { VariantOnCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUncheckedUpdateWithoutFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantOnCustomFieldUpdateWithoutFieldInputObjectSchema),
        z.lazy(
          () =>
            VariantOnCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema =
  Schema
