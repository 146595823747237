import { z } from 'zod'
import { CustomFieldWhereInputObjectSchema } from './objects/CustomFieldWhereInput.schema'
import { CustomFieldOrderByWithAggregationInputObjectSchema } from './objects/CustomFieldOrderByWithAggregationInput.schema'
import { CustomFieldScalarWhereWithAggregatesInputObjectSchema } from './objects/CustomFieldScalarWhereWithAggregatesInput.schema'
import { CustomFieldScalarFieldEnumSchema } from './enums/CustomFieldScalarFieldEnum.schema'

export const CustomFieldGroupBySchema = z.object({
  where: CustomFieldWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CustomFieldOrderByWithAggregationInputObjectSchema,
    CustomFieldOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CustomFieldScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CustomFieldScalarFieldEnumSchema),
})
