import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantWarehouseOrderByRelationAggregateInputObjectSchema } from './VariantWarehouseOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    variantWarehouses: z
      .lazy(() => VariantWarehouseOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const WarehouseOrderByWithRelationInputObjectSchema = Schema
