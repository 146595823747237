import { z } from 'zod'
import { ProductCustomFieldWhereInputObjectSchema } from './objects/ProductCustomFieldWhereInput.schema'
import { ProductCustomFieldOrderByWithAggregationInputObjectSchema } from './objects/ProductCustomFieldOrderByWithAggregationInput.schema'
import { ProductCustomFieldScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductCustomFieldScalarWhereWithAggregatesInput.schema'
import { ProductCustomFieldScalarFieldEnumSchema } from './enums/ProductCustomFieldScalarFieldEnum.schema'

export const ProductCustomFieldGroupBySchema = z.object({
  where: ProductCustomFieldWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductCustomFieldOrderByWithAggregationInputObjectSchema,
    ProductCustomFieldOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProductCustomFieldScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductCustomFieldScalarFieldEnumSchema),
})
