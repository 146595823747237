import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SuggestedProductItemOriginalCountOrderByAggregateInputObjectSchema } from './SuggestedProductItemOriginalCountOrderByAggregateInput.schema'
import { SuggestedProductItemOriginalAvgOrderByAggregateInputObjectSchema } from './SuggestedProductItemOriginalAvgOrderByAggregateInput.schema'
import { SuggestedProductItemOriginalMaxOrderByAggregateInputObjectSchema } from './SuggestedProductItemOriginalMaxOrderByAggregateInput.schema'
import { SuggestedProductItemOriginalMinOrderByAggregateInputObjectSchema } from './SuggestedProductItemOriginalMinOrderByAggregateInput.schema'
import { SuggestedProductItemOriginalSumOrderByAggregateInputObjectSchema } from './SuggestedProductItemOriginalSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      productVariantId: z.lazy(() => SortOrderSchema).optional(),
      amount: z.lazy(() => SortOrderSchema).optional(),
      usage: z.lazy(() => SortOrderSchema).optional(),
      useAmount: z.lazy(() => SortOrderSchema).optional(),
      useUnit: z.lazy(() => SortOrderSchema).optional(),
      useFrequency: z.lazy(() => SortOrderSchema).optional(),
      useTime: z.lazy(() => SortOrderSchema).optional(),
      useDuration: z.lazy(() => SortOrderSchema).optional(),
      useDurationUnit: z.lazy(() => SortOrderSchema).optional(),
      refillTime: z.lazy(() => SortOrderSchema).optional(),
      refillTimeUnit: z.lazy(() => SortOrderSchema).optional(),
      note: z.lazy(() => SortOrderSchema).optional(),
      caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
      warehouse: z.lazy(() => SortOrderSchema).optional(),
      customFields: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(
          () =>
            SuggestedProductItemOriginalCountOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _avg: z
        .lazy(
          () =>
            SuggestedProductItemOriginalAvgOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _max: z
        .lazy(
          () =>
            SuggestedProductItemOriginalMaxOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _min: z
        .lazy(
          () =>
            SuggestedProductItemOriginalMinOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _sum: z
        .lazy(
          () =>
            SuggestedProductItemOriginalSumOrderByAggregateInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const SuggestedProductItemOriginalOrderByWithAggregationInputObjectSchema =
  Schema
