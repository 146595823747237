import {
  AddBusiness,
  AdUnits,
  AssignmentTurnedIn,
  AutoFixHigh,
  Bolt,
  CalendarMonth,
  Category,
  Description,
  ExpandMoreSharp,
  Home,
  HourglassFull,
  LocalActivity,
  Medication,
  Newspaper,
  NoteAlt,
  Notifications,
  Person,
  ProductionQuantityLimits,
  ShoppingCart,
  SupervisorAccount,
  ViewCarousel,
} from '@mui/icons-material'
import { Box, Divider, styled, Typography } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { FC, useEffect, useState } from 'react'
import { Menu, MenuProps, usePermissions, useSidebarState } from 'react-admin'
import { useLocation } from 'react-router-dom'

const MenuDivider: FC = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 1 }}>
    <Divider sx={{ width: '80%' }} />
  </Box>
)
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  height: 'auto !important',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: '0 0.5rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: '0',
}))

export const SidebarMenu: FC<MenuProps> = () => {
  const [open] = useSidebarState()
  const [expanded, setExpanded] = useState<string | false>(false)
  const location = useLocation()
  const { isLoading, permissions } = usePermissions()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const checkPermission = (authorizedRoles: string[]) => {
    if (permissions === 'SUPER_ADMIN') {
      return true
    }

    return authorizedRoles.includes(permissions)
    // return true
  }
  useEffect(() => {
    const pathname = location.pathname
    if (pathname.includes('Banner')) {
      setExpanded('panel-banner')
    } else if (
      pathname.includes('/admin') ||
      pathname.includes('/partnerUser')
    ) {
      setExpanded('panel-user')
    } else if (pathname.includes('/doctor')) {
      setExpanded('panel-doctor')
    } else if (pathname.includes('/patient')) {
      setExpanded('panel-patient')
    } else if (pathname.includes('/product')) {
      setExpanded('panel-product')
    } else if (
      pathname.includes('/partner') ||
      pathname.includes('/procedure')
    ) {
      setExpanded('panel-partner')
    }
  }, [location.pathname])

  if (isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  return (
    <Menu>
      {checkPermission(['ADMIN']) && (
        <Accordion
          expanded={expanded === 'panel-user'}
          onChange={handleChange('panel-user')}
        >
          <AccordionSummary aria-controls="panel-user-content" id="panel-user">
            {open ? (
              <>
                <SupervisorAccount sx={{ marginRight: '1rem' }} />
                <Typography>Account</Typography>
              </>
            ) : (
              <SupervisorAccount />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Menu.Item to="/admin" primaryText="Admin" leftIcon={<Person />} />
            <Menu.Item
              to="/partnerUser"
              primaryText="Partner"
              leftIcon={<Person />}
            />
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission([
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DOCTOR_ADMIN',
      ]) && (
        <Accordion
          expanded={expanded === 'panel-doctor'}
          onChange={handleChange('panel-doctor')}
        >
          <AccordionSummary
            aria-controls="panel-doctor-content"
            id="panel-doctor"
          >
            {open ? (
              <>
                <Medication sx={{ marginRight: '1rem' }} />
                <Typography>Doctor</Typography>
              </>
            ) : (
              <Medication />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'FINANCE',
              'DOCTOR_ADMIN',
            ]) && [
              <Menu.Item
                to="/doctor"
                primaryText="แพทย์"
                leftIcon={<Medication />}
              />,
            ]}
            {checkPermission(['ADMIN', 'DOCTOR_ADMIN']) && [
              <Menu.Item
                to="/doctorCategory"
                primaryText="หมวดหมู่แพทย์"
                leftIcon={<Category />}
              />,
              <Menu.Item
                to="/doctorKycData"
                primaryText="การยืนยันตัวตน (แพทย์)"
                leftIcon={<AssignmentTurnedIn />}
              />,
            ]}
            {checkPermission(['ADMIN', 'CUSTOMER_SUPPORT', 'DOCTOR_ADMIN']) && [
              <Menu.Item
                to="/doctorScheduler"
                primaryText="ตารางนัดหมาย"
                leftIcon={<CalendarMonth />}
              />,
              <Menu.Item
                to="/appointment"
                primaryText="ข้อมูลนัดหมาย"
                leftIcon={<NoteAlt />}
              />,
            ]}
            {/* <Menu.Item
            to="/doctorQueueMonitoring"
            primaryText="Queue Monitoring"
            leftIcon={<Terminal />}
          /> */}
            {checkPermission(['ADMIN', 'DOCTOR_ADMIN']) && [
              <Menu.Item
                to="/doctorConsent"
                primaryText="ฟอร์มการยินยอม (แพทย์)"
                leftIcon={<Description />}
              />,
            ]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission([
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'MARKETING',
      ]) && (
        <Accordion
          expanded={expanded === 'panel-patient'}
          onChange={handleChange('panel-patient')}
        >
          <AccordionSummary
            aria-controls="panel-patient-content"
            id="panel-patient"
          >
            {open ? (
              <>
                <Person sx={{ marginRight: '1rem' }} />
                <Typography>Patient</Typography>
              </>
            ) : (
              <Person />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'FINANCE',
            ]) && [
              <Menu.Item
                key="/patient"
                to="/patient"
                primaryText="คนไข้"
                leftIcon={<Person />}
              />,
            ]}
            {checkPermission(['ADMIN', 'CUSTOMER_SUPPORT']) && [
              <Menu.Item
                key="/patientKycData"
                to="/patientKycData"
                primaryText="การยืนยันตัวตน (คนไข้)"
                leftIcon={<AssignmentTurnedIn />}
              />,
            ]}
            {checkPermission(['ADMIN']) && [
              <Menu.Item
                key="/patientConsent"
                to="/patientConsent"
                primaryText="ฟอร์มการยินยอม (คนไข้)"
                leftIcon={<Description />}
              />,
            ]}
            {checkPermission(['ADMIN', 'MARKETING']) && (
              <Menu.Item
                to="/notification"
                primaryText="การแจ้งเตือน"
                leftIcon={<Notifications />}
              />
            )}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission([
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'OPERATION',
        'FINANCE',
        'STORE_ADMIN',
      ]) && (
        <Accordion
          expanded={expanded === 'panel-product'}
          onChange={handleChange('panel-product')}
        >
          <AccordionSummary
            aria-controls="panel-product-content"
            id="panel-product"
          >
            {open ? (
              <>
                <ProductionQuantityLimits sx={{ marginRight: '1rem' }} />
                <Typography>ข้อมูลสินค้า</Typography>
              </>
            ) : (
              <ProductionQuantityLimits />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'OPERATION',
              'FINANCE',
              'STORE_ADMIN',
            ]) && [<Menu.Item to="/product" primaryText="สินค้าทั้งหมด" />]}
            {checkPermission(['ADMIN', 'OPERATION', 'STORE_ADMIN']) && [
              <Menu.Item to="/productCategory" primaryText="หมวดหมู่สินค้า" />,
              <Menu.Item to="/productBrand" primaryText="แบรนด์" />,
            ]}
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'OPERATION',
              'FINANCE',
              'STORE_ADMIN',
            ]) && [
              <Menu.Item to="/productVariant" primaryText="รหัสสินค้า (SKU)" />,
            ]}
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'OPERATION',
              'STORE_ADMIN',
            ]) && [
              <Menu.Item
                to="/productSearch"
                primaryText="ค้นหาสินค้า (Active)"
              />,
            ]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission([
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DEAL_ADMIN',
      ]) && (
        <Accordion
          expanded={expanded === 'panel-partner'}
          onChange={handleChange('panel-partner')}
        >
          <AccordionSummary
            aria-controls="panel-partner-content"
            id="panel-partner"
          >
            {open ? (
              <>
                <AddBusiness sx={{ marginRight: '1rem' }} />
                <Typography>ดีลหัตถการ และคลินิก</Typography>
              </>
            ) : (
              <AddBusiness />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'FINANCE',
              'DEAL_ADMIN',
            ]) && [<Menu.Item to="/procedure" primaryText="ดีลทั้งหมด" />]}
            {checkPermission(['ADMIN', 'FINANCE', 'DEAL_ADMIN']) && [
              <Menu.Item to="/procedureCategory" primaryText="หมวดหมู่ดีล" />,
            ]}
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'FINANCE',
              'DEAL_ADMIN',
            ]) && [
              <Menu.Item
                to="/procedureSearch"
                primaryText="ค้นหาดีลหัตการ (Active)"
              />,
            ]}
            {checkPermission([
              'ADMIN',
              'CUSTOMER_SUPPORT',
              'FINANCE',
              'DEAL_ADMIN',
            ]) && [<Menu.Item to="/partner" primaryText="คลินิก (Partner)" />]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission(['ADMIN', 'DEAL_ADMIN']) && (
        <Accordion
          expanded={expanded === 'panel-carousel'}
          onChange={handleChange('panel-carousel')}
        >
          <AccordionSummary
            aria-controls="panel-carousel-content"
            id="panel-carousel"
          >
            {open ? (
              <>
                <ViewCarousel sx={{ marginRight: '1rem' }} />
                <Typography>Carousel</Typography>
              </>
            ) : (
              <ViewCarousel />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Menu.Item to="/carouselProcedure" primaryText="หัตถการ" />
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission(['ADMIN', 'MARKETING', 'STORE_ADMIN']) && (
        <Accordion
          expanded={expanded === 'panel-flashsale'}
          onChange={handleChange('panel-flashsale')}
        >
          <AccordionSummary
            aria-controls="panel-flashsale-content"
            id="panel-flashsale"
          >
            {open ? (
              <>
                <Bolt sx={{ marginRight: '1rem' }} />
                <Typography>Flash Sale</Typography>
              </>
            ) : (
              <Bolt />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission(['ADMIN', 'MARKETING', 'STORE_ADMIN']) && [
              <Menu.Item to="/flashsaleProduct" primaryText="สินค้า" />,
            ]}
            {checkPermission(['ADMIN', 'MARKETING']) && [
              <Menu.Item to="/flashsaleProcedure" primaryText="หัตถการ" />,
            ]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission([
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'MARKETING',
      ]) && (
        <Accordion
          expanded={expanded === 'panel-coupon'}
          onChange={handleChange('panel-coupon')}
        >
          <AccordionSummary
            aria-controls="panel-coupon-content"
            id="panel-flashsale"
          >
            {open ? (
              <>
                <LocalActivity sx={{ marginRight: '1rem' }} />
                <Typography>Coupon</Typography>
              </>
            ) : (
              <LocalActivity />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission(['ADMIN', 'MARKETING']) && [
              <Menu.Item
                to="/couponGroup/create"
                primaryText="Generate coupon"
              />,
            ]}
            {checkPermission(['ADMIN', 'FINANCE', 'MARKETING']) && [
              <Menu.Item to="/couponGroup" primaryText="Coupon group" />,
            ]}
            {checkPermission([
              'ADMIN',
              'PHARMACIST',
              'CUSTOMER_SUPPORT',
              'FINANCE',
              'MARKETING',
            ]) && [<Menu.Item to="/coupon" primaryText="Coupon" />]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission(['ADMIN', 'MARKETING', 'DEAL_ADMIN']) && (
        <Accordion
          expanded={expanded === 'panel-banner'}
          onChange={handleChange('panel-banner')}
        >
          <AccordionSummary
            aria-controls="panel-banner-content"
            id="panel-banner"
          >
            {open ? (
              <>
                <AdUnits sx={{ marginRight: '1rem' }} />
                <Typography>Banner</Typography>
              </>
            ) : (
              <AdUnits />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {checkPermission(['ADMIN', 'MARKETING']) && [
              <Menu.Item
                to="/patientHomeBanner"
                primaryText="Patient app home banner"
                leftIcon={<Home />}
              />,
              <Menu.Item
                to="/productBanner"
                primaryText="Product store banner"
                leftIcon={<ShoppingCart />}
              />,
            ]}
            {checkPermission(['ADMIN', 'MARKETING', 'DEAL_ADMIN']) && (
              <Menu.Item
                to="/procedureBanner"
                primaryText="Procedure deal banner"
                leftIcon={<AutoFixHigh />}
              />
            )}
            {checkPermission(['ADMIN', 'MARKETING']) && [
              <Menu.Item
                to="/doctorBanner"
                primaryText="Doctor banner"
                leftIcon={<Medication />}
              />,
              <Menu.Item
                to="/waitingBanner"
                primaryText="Waiting banner"
                leftIcon={<HourglassFull />}
              />,
            ]}
            <MenuDivider />
          </AccordionDetails>
        </Accordion>
      )}
      {checkPermission(['ADMIN', 'MARKETING']) && (
        <Menu.Item
          to="/article"
          primaryText="Patient app article"
          leftIcon={<Newspaper />}
          sx={{ py: 2 }}
        />
      )}
      {/* {checkPermission(['ADMIN', 'OPERATION']) && (
        <Menu.Item
          to="/shippingProvider"
          primaryText="Shipping Provider"
          leftIcon={<LocalShipping />}
          sx={{ py: 2 }}
        />
      )} */}
    </Menu>
  )
}
