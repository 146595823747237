import { z } from 'zod'
import { ProductCreateNestedOneWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateNestedOneWithoutProductOnCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldCreateWithoutFieldInput> = z
  .object({
    product: z.lazy(
      () => ProductCreateNestedOneWithoutProductOnCustomFieldInputObjectSchema,
    ),
  })
  .strict()

export const ProductCustomFieldCreateWithoutFieldInputObjectSchema = Schema
