import { z } from 'zod'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { NestedEnumUseFrequencyNullableWithAggregatesFilterObjectSchema } from './NestedEnumUseFrequencyNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumUseFrequencyNullableFilterObjectSchema } from './NestedEnumUseFrequencyNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUseFrequencyNullableWithAggregatesFilter> = z
  .object({
    equals: z
      .lazy(() => UseFrequencySchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UseFrequencySchema),
        z.lazy(
          () => NestedEnumUseFrequencyNullableWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
    _min: z
      .lazy(() => NestedEnumUseFrequencyNullableFilterObjectSchema)
      .optional(),
    _max: z
      .lazy(() => NestedEnumUseFrequencyNullableFilterObjectSchema)
      .optional(),
  })
  .strict()

export const EnumUseFrequencyNullableWithAggregatesFilterObjectSchema = Schema
