import { z } from 'zod'
import { CategoryArgsObjectSchema } from './CategoryArgs.schema'
import { CategoryFindManySchema } from '../findManyCategory.schema'
import { ProductCategoryFindManySchema } from '../findManyProductCategory.schema'
import { CategoryCountOutputTypeArgsObjectSchema } from './CategoryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategorySelect> = z
  .object({
    id: z.boolean().optional(),
    active: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    priority: z.boolean().optional(),
    icon: z.boolean().optional(),
    path: z.boolean().optional(),
    parent: z
      .union([z.boolean(), z.lazy(() => CategoryArgsObjectSchema)])
      .optional(),
    parentId: z.boolean().optional(),
    children: z
      .union([z.boolean(), z.lazy(() => CategoryFindManySchema)])
      .optional(),
    products: z
      .union([z.boolean(), z.lazy(() => ProductCategoryFindManySchema)])
      .optional(),
    searchField: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CategoryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CategorySelectObjectSchema = Schema
