import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldCreateWithoutProductInputObjectSchema } from './ProductCustomFieldCreateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldCreateOrConnectWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema =
  Schema
