import { z } from 'zod'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableEnumUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUnitFieldUpdateOperationsInput.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { ProductUpdateOneRequiredWithoutVariantsNestedInputObjectSchema } from './ProductUpdateOneRequiredWithoutVariantsNestedInput.schema'
import { VariantWarehouseUpdateManyWithoutVariantNestedInputObjectSchema } from './VariantWarehouseUpdateManyWithoutVariantNestedInput.schema'
import { FlashsaleItemUpdateManyWithoutVariantNestedInputObjectSchema } from './FlashsaleItemUpdateManyWithoutVariantNestedInput.schema'
import { VariantPictureUpdateManyWithoutVariantNestedInputObjectSchema } from './VariantPictureUpdateManyWithoutVariantNestedInput.schema'
import { ProductReviewUpdateManyWithoutVariantNestedInputObjectSchema } from './ProductReviewUpdateManyWithoutVariantNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.VariantUpdateWithoutVariantOnCustomFieldsInput> =
  z
    .object({
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      sku: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      labelTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      batchAndExpireDate: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      size: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      cost: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      tagPrice: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      sellingPrice: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      inventory: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      canExpress: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2DrugItemId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      inventoryUpdatedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      unit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      sellingUnit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      descriptionTr: z
        .union([
          z.lazy(() => NullableJsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      rank: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      product: z
        .lazy(
          () => ProductUpdateOneRequiredWithoutVariantsNestedInputObjectSchema,
        )
        .optional(),
      variantWarehouses: z
        .lazy(
          () => VariantWarehouseUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      flashsaleItem: z
        .lazy(
          () => FlashsaleItemUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () => VariantPictureUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
      productReviews: z
        .lazy(
          () => ProductReviewUpdateManyWithoutVariantNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const VariantUpdateWithoutVariantOnCustomFieldsInputObjectSchema = Schema
