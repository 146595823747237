import { z } from 'zod'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldUpdateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUpdateWithoutVariantInput.schema'
import { VariantOnCustomFieldUncheckedUpdateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUncheckedUpdateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantOnCustomFieldUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () =>
            VariantOnCustomFieldUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
