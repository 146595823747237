import * as z from "zod"
import { CompleteVariantWarehouse, RelatedVariantWarehouseModel } from "./index"

export const WarehouseModel = z.object({
  id: z.number().int(),
  name: z.string(),
  code: z.string(),
  active: z.boolean(),
})

export interface CompleteWarehouse extends z.infer<typeof WarehouseModel> {
  variantWarehouses: CompleteVariantWarehouse[]
}

/**
 * RelatedWarehouseModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedWarehouseModel: z.ZodSchema<CompleteWarehouse> = z.lazy(() => WarehouseModel.extend({
  variantWarehouses: RelatedVariantWarehouseModel.array(),
}))
