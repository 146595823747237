import { z } from 'zod'
import { CustomFieldUpdateInputObjectSchema } from './objects/CustomFieldUpdateInput.schema'
import { CustomFieldUncheckedUpdateInputObjectSchema } from './objects/CustomFieldUncheckedUpdateInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './objects/CustomFieldWhereUniqueInput.schema'

export const CustomFieldUpdateOneSchema = z.object({
  data: z.union([
    CustomFieldUpdateInputObjectSchema,
    CustomFieldUncheckedUpdateInputObjectSchema,
  ]),
  where: CustomFieldWhereUniqueInputObjectSchema,
})
