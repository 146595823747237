import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    active: z.literal(true).optional(),
    searchable: z.literal(true).optional(),
    nameTr: z.literal(true).optional(),
    searchField: z.literal(true).optional(),
    regNo: z.literal(true).optional(),
    brandId: z.literal(true).optional(),
    type: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    salesType: z.literal(true).optional(),
    drugGroup: z.literal(true).optional(),
    propertyTr: z.literal(true).optional(),
    drugType: z.literal(true).optional(),
    descriptionTr: z.literal(true).optional(),
    sideEffectTr: z.literal(true).optional(),
    cautionTr: z.literal(true).optional(),
    genericTr: z.literal(true).optional(),
    genericSearch: z.literal(true).optional(),
    useAmount: z.literal(true).optional(),
    instructionTr: z.literal(true).optional(),
    pharmacyNoteTr: z.literal(true).optional(),
    pharmacyNote: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    deletedAt: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
    auxiliaryLabel: z.literal(true).optional(),
    productForms: z.literal(true).optional(),
    skinType: z.literal(true).optional(),
    concerns: z.literal(true).optional(),
    attributes: z.literal(true).optional(),
    usage: z.literal(true).optional(),
    useUnit: z.literal(true).optional(),
    useFrequency: z.literal(true).optional(),
    useTime: z.literal(true).optional(),
    ingredient: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ProductCountAggregateInputObjectSchema = Schema
