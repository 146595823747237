import { z } from 'zod'
import { CustomFieldUpdateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUpdateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedCreateWithoutVariantCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldUpsertWithoutVariantCustomFieldsInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => CustomFieldUpdateWithoutVariantCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CustomFieldUpsertWithoutVariantCustomFieldsInputObjectSchema =
  Schema
