import { z } from 'zod'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldCreateWithoutFieldInput.schema'
import { VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUncheckedCreateWithoutFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateOrConnectWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => VariantOnCustomFieldCreateWithoutFieldInputObjectSchema),
        z.lazy(
          () =>
            VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema =
  Schema
