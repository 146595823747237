import { z } from 'zod'
import { CustomFieldOrderByWithRelationInputObjectSchema } from './objects/CustomFieldOrderByWithRelationInput.schema'
import { CustomFieldWhereInputObjectSchema } from './objects/CustomFieldWhereInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './objects/CustomFieldWhereUniqueInput.schema'
import { CustomFieldCountAggregateInputObjectSchema } from './objects/CustomFieldCountAggregateInput.schema'
import { CustomFieldMinAggregateInputObjectSchema } from './objects/CustomFieldMinAggregateInput.schema'
import { CustomFieldMaxAggregateInputObjectSchema } from './objects/CustomFieldMaxAggregateInput.schema'
import { CustomFieldAvgAggregateInputObjectSchema } from './objects/CustomFieldAvgAggregateInput.schema'
import { CustomFieldSumAggregateInputObjectSchema } from './objects/CustomFieldSumAggregateInput.schema'

export const CustomFieldAggregateSchema = z.object({
  orderBy: z
    .union([
      CustomFieldOrderByWithRelationInputObjectSchema,
      CustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CustomFieldWhereInputObjectSchema.optional(),
  cursor: CustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CustomFieldCountAggregateInputObjectSchema])
    .optional(),
  _min: CustomFieldMinAggregateInputObjectSchema.optional(),
  _max: CustomFieldMaxAggregateInputObjectSchema.optional(),
  _avg: CustomFieldAvgAggregateInputObjectSchema.optional(),
  _sum: CustomFieldSumAggregateInputObjectSchema.optional(),
})
