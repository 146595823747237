import { z } from 'zod'
import { VariantUpdateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUpdateWithoutVariantOnCustomFieldsInput.schema'
import { VariantUncheckedUpdateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUncheckedUpdateWithoutVariantOnCustomFieldsInput.schema'
import { VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateWithoutVariantOnCustomFieldsInput.schema'
import { VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUncheckedCreateWithoutVariantOnCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpsertWithoutVariantOnCustomFieldsInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => VariantUpdateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            VariantUncheckedUpdateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantUpsertWithoutVariantOnCustomFieldsInputObjectSchema = Schema
