import { z } from 'zod'
import { ProductCustomFieldCreateInputObjectSchema } from './objects/ProductCustomFieldCreateInput.schema'
import { ProductCustomFieldUncheckedCreateInputObjectSchema } from './objects/ProductCustomFieldUncheckedCreateInput.schema'

export const ProductCustomFieldCreateOneSchema = z.object({
  data: z.union([
    ProductCustomFieldCreateInputObjectSchema,
    ProductCustomFieldUncheckedCreateInputObjectSchema,
  ]),
})
