import { z } from 'zod'
import { ProductCustomFieldCreateWithoutProductInputObjectSchema } from './ProductCustomFieldCreateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutProductInput.schema'
import { ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema } from './ProductCustomFieldCreateOrConnectWithoutProductInput.schema'
import { ProductCustomFieldCreateManyProductInputEnvelopeObjectSchema } from './ProductCustomFieldCreateManyProductInputEnvelope.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUncheckedCreateNestedManyWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProductCustomFieldCreateManyProductInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCustomFieldUncheckedCreateNestedManyWithoutProductInputObjectSchema =
  Schema
