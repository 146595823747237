import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithoutProductInputObjectSchema } from './ProductCustomFieldUpdateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedUpdateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedUpdateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpdateWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductCustomFieldUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductCustomFieldUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldUpdateWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
