import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    active: z.literal(true).optional(),
    nameTr: z.literal(true).optional(),
    descriptionTr: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    icon: z.literal(true).optional(),
    path: z.literal(true).optional(),
    parentId: z.literal(true).optional(),
    searchField: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const CategoryCountAggregateInputObjectSchema = Schema
