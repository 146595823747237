import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'
import { EnumCustomFieldTypeFieldUpdateOperationsInputObjectSchema } from './EnumCustomFieldTypeFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { ProductCustomFieldUncheckedUpdateManyWithoutFieldNestedInputObjectSchema } from './ProductCustomFieldUncheckedUpdateManyWithoutFieldNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      fieldType: z
        .union([
          z.lazy(() => CustomFieldTypeSchema),
          z.lazy(
            () => EnumCustomFieldTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      priority: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      fieldOptionsTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      searchField: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      productCustomField: z
        .lazy(
          () =>
            ProductCustomFieldUncheckedUpdateManyWithoutFieldNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInputObjectSchema =
  Schema
