import { z } from 'zod'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateWithoutVariantOnCustomFieldsInput.schema'
import { VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUncheckedCreateWithoutVariantOnCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateOrConnectWithoutVariantOnCustomFieldsInput> =
  z
    .object({
      where: z.lazy(() => VariantWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
        z.lazy(
          () =>
            VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantCreateOrConnectWithoutVariantOnCustomFieldsInputObjectSchema =
  Schema
