import { z } from 'zod'
import { ProductCustomFieldCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutFieldInput.schema'
import { ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateOrConnectWithoutFieldInput.schema'
import { ProductCustomFieldCreateManyFieldInputEnvelopeObjectSchema } from './ProductCustomFieldCreateManyFieldInputEnvelope.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldCreateNestedManyWithoutFieldInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema),
          z
            .lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCustomFieldCreateManyFieldInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCustomFieldCreateNestedManyWithoutFieldInputObjectSchema =
  Schema
