import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { NestedEnumUseTimeNullableWithAggregatesFilterObjectSchema } from './NestedEnumUseTimeNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumUseTimeNullableFilterObjectSchema } from './NestedEnumUseTimeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumUseTimeNullableWithAggregatesFilter> = z
  .object({
    equals: z
      .lazy(() => UseTimeSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => NestedEnumUseTimeNullableWithAggregatesFilterObjectSchema),
      ])
      .optional()
      .nullable(),
    _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumUseTimeNullableFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumUseTimeNullableFilterObjectSchema).optional(),
  })
  .strict()

export const EnumUseTimeNullableWithAggregatesFilterObjectSchema = Schema
