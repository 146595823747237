import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { NestedEnumUseTimeNullableFilterObjectSchema } from './NestedEnumUseTimeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumUseTimeNullableFilter> = z
  .object({
    equals: z
      .lazy(() => UseTimeSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => NestedEnumUseTimeNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumUseTimeNullableFilterObjectSchema = Schema
