import {
  ProductCustomFieldsModel,
  TrFieldModel,
  TrFieldOptionalModel,
} from '@ssch-backend/share-model'
import { generateSearchField } from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'

import {
  DrugGroupSchema,
  DrugTypeSchema,
  ProductTypeSchema,
  SalesTypeSchema,
} from '../../generated/trpc/schemas'
import {
  BrandModel,
  CategoryModelResponse,
  ProductModel,
  ProductPictureModel,
  ProductVideoModel,
  VariantModelResponse,
} from '../../generated/zod'

export const GenerateUploadProductMediaInputModel = z.object({
  extension: z.string(),
  fieldPath: z.string(),
})
export type GenerateUploadProductMediaInput = z.infer<
  typeof GenerateUploadProductMediaInputModel
>

export const GetProductTRPCInputModel = z.object({
  productId: z.number(),
})
export const GetProductsTRPCInputModel = z.object({
  page: z.number().default(1),
  limit: z.number().default(10),
  orderBy: z.enum(['id', 'priority']).optional(),
  orderDirection: z.enum(['asc', 'desc']).optional(),
  product: z.string().optional(),
  productId: z.number().optional(),
  brandId: z.number().optional(),
  categoryId: z.preprocess(
    (val) =>
      typeof val === 'string' ? String(val).split(',').map(Number) : val,
    z.number().array().optional(),
  ),
  type: ProductTypeSchema.optional(),
  drugGroup: DrugGroupSchema.optional(),
  genericName: z.string().optional(),
  productForms: z.preprocess(
    (val) =>
      typeof val === 'string' ? String(val).split(',').map(Number) : val,
    z.number().array().optional(),
  ),
})
export type GetProductTRPCInput = z.infer<typeof GetProductTRPCInputModel>
export type GetProductsTRPCInput = z.infer<typeof GetProductsTRPCInputModel>

export const ProductPictureSchema = z.object({
  src: z.string(),
  gsPath: z.string(),
})
export type ProductPicture = z.infer<typeof ProductPictureSchema>

export const ProductVideoSchema = z.object({
  src: z.string(),
  gsPath: z.string(),
  thumbnailSrc: z.string().optional(),
  thumbnailGsPath: z.string(),
})
export type ProductVideo = z.infer<typeof ProductVideoSchema>
const SelectOptionModel = z.object({
  label: z.string(),
  value: z.number(),
})
export type SelectOptionModel = z.infer<typeof SelectOptionModel>

export const ProductWithCustomFieldInputModel = z.object({
  productId: z.number().int(),
  active: z.boolean(),
  searchable: z.boolean(),
  nameTr: TrFieldModel,
  regNo: z.string().optional().default(''),
  descriptionTr: TrFieldOptionalModel,
  instructionTr: TrFieldOptionalModel,
  sideEffectTr: TrFieldOptionalModel,
  cautionTr: TrFieldOptionalModel,
  pharmacyNote: z.string().optional(),
  type: ProductTypeSchema,
  drugType: DrugTypeSchema.optional(),
  salesType: SalesTypeSchema,
  drugGroup: DrugGroupSchema.optional(),
  propertyTr: TrFieldOptionalModel,
  genericTr: TrFieldOptionalModel,
  pharmacyNoteTr: TrFieldOptionalModel,
  priority: z.number().int().min(0),
  useAmount: z.string(),
  // Search Fields
  searchField: z.string(),
  genericSearch: z.string(),
  // Fields for relation tables
  brandId: z.number(),
  categoryId: z.number().array().optional(),
  pictures: z.array(ProductPictureSchema),
  videos: z.array(ProductVideoSchema).optional(),
  drugClassifications: SelectOptionModel.optional(),
  auxiliaryLabel: SelectOptionModel.optional(),
  productForms: z.array(SelectOptionModel).optional(),
  skinTypes: z.array(SelectOptionModel).optional(),
  concerns: z.array(SelectOptionModel).optional(),
  attribute: SelectOptionModel.optional(),
  usage: SelectOptionModel,
  useUnit: SelectOptionModel,
  useFrequency: SelectOptionModel,
})
export type ProductWithCustomFieldInput = z.infer<
  typeof ProductWithCustomFieldInputModel
>

export const transformProductSearchFields = <
  T extends Partial<ProductWithCustomFieldInput>,
>(
  data: T,
) => {
  const SEARCH_FIELD_DELIMITER = '||'
  const searchField = data?.nameTr
    ? generateSearchField(data.nameTr, SEARCH_FIELD_DELIMITER)
    : ''
  const genericSearch = data?.genericTr
    ? generateSearchField(data.genericTr, SEARCH_FIELD_DELIMITER)
    : ''

  return { ...data, searchField, genericSearch }
}

export const CreateProductWithCustomFieldInputModel =
  ProductWithCustomFieldInputModel.omit({
    productId: true,
    active: true,
    searchable: true,
    genericSearch: true,
    searchField: true,
  }).transform(transformProductSearchFields)

export type CreateProductWithCustomFieldInput = z.infer<
  typeof CreateProductWithCustomFieldInputModel
>

export const UpdateProductWithCustomFieldInputModel =
  ProductWithCustomFieldInputModel.omit({
    active: true,
    searchable: true,
    genericSearch: true,
    searchField: true,
  })
    .required({ productId: true })
    .transform(transformProductSearchFields)
export type UpdateProductWithCustomFieldInput = z.infer<
  typeof UpdateProductWithCustomFieldInputModel
>

export const ProductResponseModel = ProductModel.omit({
  nameTr: true,
  descriptionTr: true,
  instructionTr: true,
  sideEffectTr: true,
  cautionTr: true,
  propertyTr: true,
  genericTr: true,
  pharmacyNoteTr: true,
}).extend({
  nameTr: TrFieldModel,
  descriptionTr: TrFieldOptionalModel,
  instructionTr: TrFieldOptionalModel,
  sideEffectTr: TrFieldOptionalModel,
  cautionTr: TrFieldOptionalModel,
  propertyTr: TrFieldOptionalModel,
  genericTr: TrFieldOptionalModel,
  pharmacyNoteTr: TrFieldOptionalModel,
})

export const ProductWithBrandAndCategoriesModel = ProductResponseModel.extend({
  brand: BrandModel,
  categories: CategoryModelResponse.array(),
})

export type ProductResponse = z.infer<typeof ProductResponseModel>
export type ProductWithBrandAndCategoriesResponse = z.infer<
  typeof ProductWithBrandAndCategoriesModel
>

export const ProductWithCustomFieldResponseModel =
  ProductWithBrandAndCategoriesModel.extend({
    videos: ProductVideoModel.array(),
    variants: VariantModelResponse.array(),
    pictures: ProductPictureModel.array(),
    customFields: ProductCustomFieldsModel.partial(),
  })
export type ProductWithCustomFieldResponse = z.infer<
  typeof ProductWithCustomFieldResponseModel
>

export const GetProductResponseModel = z.object({
  products: ProductResponseModel.array(),
  count: z.number(),
})
export type GetProductResponse = z.infer<typeof GetProductResponseModel>

export const GetProductWithBrandAndCategoriesResponseModel = z.object({
  products: ProductWithBrandAndCategoriesModel.array(),
  count: z.number(),
})
export type GetProductWithBrandAndCategoriesResponse = z.infer<
  typeof GetProductWithBrandAndCategoriesResponseModel
>

export const ProductWithCustomFieldBaseInputModel =
  ProductWithCustomFieldInputModel.omit({
    productId: true,
    active: true,
    searchable: true,
    drugGroup: true,
    drugType: true,
    propertyTr: true,
    descriptionTr: true,
    sideEffectTr: true,
    cautionTr: true,
    genericTr: true,
    // custom field
    drugClassifications: true,
    auxiliaryLabel: true,
    productForms: true,
    skinTypes: true,
    concerns: true,
    attribute: true,
  })

export const ProductWithCustomFieldForDrugInputModel =
  ProductWithCustomFieldBaseInputModel.extend({
    type: z.literal('DRUG'),
    drugClassifications: SelectOptionModel,
    auxiliaryLabel: SelectOptionModel,
    productForms: z.array(SelectOptionModel).optional(),
    skinTypes: z.array(SelectOptionModel),
    concerns: z.array(SelectOptionModel),
    attribute: SelectOptionModel,
    drugGroup: DrugGroupSchema.optional(),
    drugType: DrugTypeSchema.optional(),
    propertyTr: TrFieldOptionalModel,
    descriptionTr: TrFieldOptionalModel,
    sideEffectTr: TrFieldOptionalModel,
    cautionTr: TrFieldOptionalModel,
    genericTr: TrFieldOptionalModel,
  })

export const ProductWithCustomFieldForSkincareInputModel =
  ProductWithCustomFieldBaseInputModel.extend({
    type: z.literal('SKINCARE'),
    drugClassifications: z.undefined(),
    auxiliaryLabel: z.undefined(),
    productForms: z.array(SelectOptionModel),
    skinTypes: z.array(SelectOptionModel),
    concerns: z.array(SelectOptionModel),
    attribute: SelectOptionModel,
    descriptionTr: TrFieldOptionalModel,
    cautionTr: TrFieldOptionalModel,
    genericTr: TrFieldOptionalModel,
  })

export const ProductWithCustomFieldForSupplementInputModel =
  ProductWithCustomFieldBaseInputModel.extend({
    type: z.literal('SUPPLEMENTS'),
    drugClassifications: z.undefined(),
    auxiliaryLabel: z.undefined(),
    productForms: z.array(SelectOptionModel).optional(),
    skinTypes: z.array(SelectOptionModel),
    concerns: z.array(SelectOptionModel),
    attribute: SelectOptionModel,
    descriptionTr: TrFieldModel,
    cautionTr: TrFieldOptionalModel,
    genericTr: TrFieldOptionalModel,
  })

export const ProductWithCustomFieldForToolInputModel =
  ProductWithCustomFieldBaseInputModel.extend({
    type: z.literal('TOOLS'),
    drugClassifications: z.undefined(),
    auxiliaryLabel: z.undefined(),
    productForms: z.undefined(),
    skinTypes: z.array(SelectOptionModel),
    concerns: z.array(SelectOptionModel),
    attribute: SelectOptionModel.optional(),
    descriptionTr: TrFieldOptionalModel,
    cautionTr: TrFieldModel,
    genericTr: TrFieldOptionalModel,
  })

export const ProductWithCustomFieldInputUnionModel = z.union([
  ProductWithCustomFieldForDrugInputModel,
  ProductWithCustomFieldForSkincareInputModel,
  ProductWithCustomFieldForSupplementInputModel,
  ProductWithCustomFieldForToolInputModel,
])

export type ProductWithCustomFieldInputUnion = z.infer<
  typeof ProductWithCustomFieldInputUnionModel
>

export const UpdateProductWithCustomFieldInputUnionModel = z.union([
  ProductWithCustomFieldForDrugInputModel.extend({
    productId: z.number(),
  }),
  ProductWithCustomFieldForSkincareInputModel.extend({
    productId: z.number(),
  }),
  ProductWithCustomFieldForSupplementInputModel.extend({
    productId: z.number(),
  }),
  ProductWithCustomFieldForToolInputModel.extend({
    productId: z.number(),
  }),
])
export type UpdateProductWithCustomFieldInputUnion = z.infer<
  typeof UpdateProductWithCustomFieldInputUnionModel
>

export const ToggleProductActiveStatusModel = z.object({
  productId: z.number(),
  active: z.boolean(),
})
export type ToggleProductActiveStatus = z.infer<
  typeof ToggleProductActiveStatusModel
>

export const ToggleProductActiveStatusResponseModel = ProductResponseModel.pick(
  {
    id: true,
    active: true,
    updatedAt: true,
  },
)
export type ToggleProductActiveStatusResponse = z.infer<
  typeof ToggleProductActiveStatusResponseModel
>

export const ToggleProductSearchableStatusModel = z.object({
  productId: z.number(),
  searchable: z.boolean(),
})
export type ToggleProductSearchableStatus = z.infer<
  typeof ToggleProductSearchableStatusModel
>

export const ToggleProductSearchableStatusResponseModel =
  ProductResponseModel.pick({
    id: true,
    searchable: true,
    updatedAt: true,
  })
export type ToggleProductSearchableStatusResponse = z.infer<
  typeof ToggleProductSearchableStatusResponseModel
>
