import { z } from 'zod'
import { CustomFieldCountOutputTypeSelectObjectSchema } from './CustomFieldCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => CustomFieldCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const CustomFieldCountOutputTypeArgsObjectSchema = Schema
