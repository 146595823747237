import { z } from 'zod'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { VariantWarehouseUpdateManyWithoutWarehouseNestedInputObjectSchema } from './VariantWarehouseUpdateManyWithoutWarehouseNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUpdateInput> = z
  .object({
    name: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    code: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    active: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    variantWarehouses: z
      .lazy(
        () => VariantWarehouseUpdateManyWithoutWarehouseNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const WarehouseUpdateInputObjectSchema = Schema
