import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumUseTimeNullableFilter> = z
  .object({
    equals: z
      .lazy(() => UseTimeSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => NestedEnumUseTimeNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const NestedEnumUseTimeNullableFilterObjectSchema = Schema
