import { z } from 'zod'
import { CustomFieldUpdateOneRequiredWithoutVariantCustomFieldsNestedInputObjectSchema } from './CustomFieldUpdateOneRequiredWithoutVariantCustomFieldsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateWithoutVariantInput> =
  z
    .object({
      field: z
        .lazy(
          () =>
            CustomFieldUpdateOneRequiredWithoutVariantCustomFieldsNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldUpdateWithoutVariantInputObjectSchema = Schema
