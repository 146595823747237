import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { required } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormReferenceInput } from './formReferenceInput'

interface AppointmentDetailProps {
  disabled?: boolean
}

export const AppointmentDetail: FC<AppointmentDetailProps> = ({
  disabled = false,
}) => {
  const filterPatients = (searchText: string) => {
    const splittedSearchText = searchText.trim().split(/\s+/)
    const searchOption = {
      '1': [['id'], ['prefix'], ['firstname'], ['lastname']],
      '2': [
        ['prefix', 'firstname'],
        ['firstname', 'lastname'],
      ],
      '3': [['prefix', 'firstname', 'lastname']],
    }

    if (!splittedSearchText.length) {
      return {
        OR: [],
      }
    }

    const orFilterList = [] as { [x: string]: string | number }[]
    if (splittedSearchText.length <= 3) {
      searchOption[
        splittedSearchText.length.toString() as '1' | '2' | '3'
      ].forEach((conidtionList) => {
        const conditionObject = {} as { [x: string]: string | number }
        for (let i = 0; i < splittedSearchText.length; i++) {
          if (conidtionList[i] === 'id') {
            const idCondition = parseInt(splittedSearchText[i])
            if (!isNaN(idCondition)) {
              conditionObject[conidtionList[i]] = idCondition
            }
          } else {
            conditionObject[conidtionList[i]] = splittedSearchText[i]
          }
        }

        orFilterList.push(conditionObject)
      })
    } else {
      orFilterList.push({
        prefix: splittedSearchText[0],
        firstname: splittedSearchText[1],
        lastname: splittedSearchText.slice(2).join(' '),
      })
      orFilterList.push({
        prefix: splittedSearchText[0],
        firstname: splittedSearchText
          .slice(1, splittedSearchText.length - 1)
          .join(' '),
        lastname: splittedSearchText[splittedSearchText.length - 1],
      })
    }

    return {
      OR: orFilterList,
    }
  }

  return (
    <Box sx={{ mb: 3, width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Appointment Detail
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 1 }}>
            <FormReferenceInput
              source="patientId"
              reference="patient"
              optionText={['id', 'prefix', 'firstname', 'lastname']}
              label="คนไข้"
              validate={required()}
              disabled={disabled}
              filterSearch={filterPatients}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  )
}
