import { z } from 'zod'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumCustomFieldTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => CustomFieldTypeSchema).optional(),
    })
    .strict()

export const EnumCustomFieldTypeFieldUpdateOperationsInputObjectSchema = Schema
