import { z } from 'zod'
import { ProductModel, VariantModelResponse } from '../products'
import { TrFieldModel, TrFieldOptionalModel } from './trField.model'

export const ProductSKUPictureSchema = z.object({
  src: z.string(),
  gsPath: z.string(),
})

export const ProductResponseModel = ProductModel.omit({
  nameTr: true,
  descriptionTr: true,
  instructionTr: true,
  sideEffectTr: true,
  cautionTr: true,
  propertyTr: true,
  genericTr: true,
  pharmacyNoteTr: true,
}).extend({
  nameTr: TrFieldModel,
  descriptionTr: TrFieldOptionalModel,
  instructionTr: TrFieldOptionalModel,
  sideEffectTr: TrFieldOptionalModel,
  cautionTr: TrFieldOptionalModel,
  propertyTr: TrFieldOptionalModel,
  genericTr: TrFieldOptionalModel,
  pharmacyNoteTr: TrFieldOptionalModel,
})

export const ProductVariantResponseModel = VariantModelResponse.omit({
  labelTr: true,
}).extend({
  labelTr: TrFieldModel,
})
