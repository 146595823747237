import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { VariantWarehouseListRelationFilterObjectSchema } from './VariantWarehouseListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => WarehouseWhereInputObjectSchema),
        z.lazy(() => WarehouseWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => WarehouseWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => WarehouseWhereInputObjectSchema),
        z.lazy(() => WarehouseWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    name: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    code: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    variantWarehouses: z
      .lazy(() => VariantWarehouseListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const WarehouseWhereInputObjectSchema = Schema
