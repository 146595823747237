import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    sku: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    batchAndExpireDate: z.lazy(() => SortOrderSchema).optional(),
    size: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    inventory: z.lazy(() => SortOrderSchema).optional(),
    canExpress: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    inventoryUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
    unit: z.lazy(() => SortOrderSchema).optional(),
    sellingUnit: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const VariantMinOrderByAggregateInputObjectSchema = Schema
