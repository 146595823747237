import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateManyFieldInput> = z
  .object({
    id: z.number().optional(),
    variantId: z.number(),
  })
  .strict()

export const VariantOnCustomFieldCreateManyFieldInputObjectSchema = Schema
