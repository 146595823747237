import * as z from "zod"
import { CompleteVariant, RelatedVariantModel, CompleteCustomField, RelatedCustomFieldModel } from "./index"

export const VariantOnCustomFieldModel = z.object({
  id: z.number().int(),
  variantId: z.number().int(),
  fieldId: z.number().int(),
})

export interface CompleteVariantOnCustomField extends z.infer<typeof VariantOnCustomFieldModel> {
  variant: CompleteVariant
  field: CompleteCustomField
}

/**
 * RelatedVariantOnCustomFieldModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVariantOnCustomFieldModel: z.ZodSchema<CompleteVariantOnCustomField> = z.lazy(() => VariantOnCustomFieldModel.extend({
  variant: RelatedVariantModel,
  field: RelatedCustomFieldModel,
}))
