import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldProductIdFieldIdCompoundUniqueInput> =
  z
    .object({
      productId: z.number(),
      fieldId: z.number(),
    })
    .strict()

export const ProductCustomFieldProductIdFieldIdCompoundUniqueInputObjectSchema =
  Schema
