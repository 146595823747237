import { z } from 'zod'
import { CustomFieldCreateInputObjectSchema } from './objects/CustomFieldCreateInput.schema'
import { CustomFieldUncheckedCreateInputObjectSchema } from './objects/CustomFieldUncheckedCreateInput.schema'

export const CustomFieldCreateOneSchema = z.object({
  data: z.union([
    CustomFieldCreateInputObjectSchema,
    CustomFieldUncheckedCreateInputObjectSchema,
  ]),
})
