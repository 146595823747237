import { z } from 'zod'
import { ProductCustomFieldSelectObjectSchema } from './ProductCustomFieldSelect.schema'
import { ProductCustomFieldIncludeObjectSchema } from './ProductCustomFieldInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldArgs> = z
  .object({
    select: z.lazy(() => ProductCustomFieldSelectObjectSchema).optional(),
    include: z.lazy(() => ProductCustomFieldIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductCustomFieldArgsObjectSchema = Schema
