import { z } from 'zod'
import { CustomFieldWhereInputObjectSchema } from './CustomFieldWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldRelationFilter> = z
  .object({
    is: z.lazy(() => CustomFieldWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => CustomFieldWhereInputObjectSchema).optional(),
  })
  .strict()

export const CustomFieldRelationFilterObjectSchema = Schema
