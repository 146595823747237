import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CustomFieldCountOrderByAggregateInputObjectSchema } from './CustomFieldCountOrderByAggregateInput.schema'
import { CustomFieldAvgOrderByAggregateInputObjectSchema } from './CustomFieldAvgOrderByAggregateInput.schema'
import { CustomFieldMaxOrderByAggregateInputObjectSchema } from './CustomFieldMaxOrderByAggregateInput.schema'
import { CustomFieldMinOrderByAggregateInputObjectSchema } from './CustomFieldMinOrderByAggregateInput.schema'
import { CustomFieldSumOrderByAggregateInputObjectSchema } from './CustomFieldSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    fieldType: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    fieldOptionsTr: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CustomFieldCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CustomFieldAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CustomFieldMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CustomFieldMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CustomFieldSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CustomFieldOrderByWithAggregationInputObjectSchema = Schema
