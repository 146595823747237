import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithoutFieldInputObjectSchema } from './ProductCustomFieldUpdateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedUpdateWithoutFieldInput.schema'
import { ProductCustomFieldCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductCustomFieldUpdateWithoutFieldInputObjectSchema),
        z.lazy(
          () => ProductCustomFieldUncheckedUpdateWithoutFieldInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema),
        z.lazy(
          () => ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema =
  Schema
