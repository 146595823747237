import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithoutProductInputObjectSchema } from './ProductCustomFieldUpdateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedUpdateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedUpdateWithoutProductInput.schema'
import { ProductCustomFieldCreateWithoutProductInputObjectSchema } from './ProductCustomFieldCreateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpsertWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductCustomFieldUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductCustomFieldUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldUpsertWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
