import { z } from 'zod'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'
import { NestedEnumCustomFieldTypeWithAggregatesFilterObjectSchema } from './NestedEnumCustomFieldTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumCustomFieldTypeFilterObjectSchema } from './NestedEnumCustomFieldTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumCustomFieldTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => CustomFieldTypeSchema).optional(),
    in: z
      .lazy(() => CustomFieldTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CustomFieldTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CustomFieldTypeSchema),
        z.lazy(() => NestedEnumCustomFieldTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumCustomFieldTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumCustomFieldTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumCustomFieldTypeWithAggregatesFilterObjectSchema = Schema
