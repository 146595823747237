import { z } from 'zod'
import { ProductCustomFieldCreateWithoutProductInputObjectSchema } from './ProductCustomFieldCreateWithoutProductInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutProductInput.schema'
import { ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema } from './ProductCustomFieldCreateOrConnectWithoutProductInput.schema'
import { ProductCustomFieldUpsertWithWhereUniqueWithoutProductInputObjectSchema } from './ProductCustomFieldUpsertWithWhereUniqueWithoutProductInput.schema'
import { ProductCustomFieldCreateManyProductInputEnvelopeObjectSchema } from './ProductCustomFieldCreateManyProductInputEnvelope.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithWhereUniqueWithoutProductInputObjectSchema } from './ProductCustomFieldUpdateWithWhereUniqueWithoutProductInput.schema'
import { ProductCustomFieldUpdateManyWithWhereWithoutProductInputObjectSchema } from './ProductCustomFieldUpdateManyWithWhereWithoutProductInput.schema'
import { ProductCustomFieldScalarWhereInputObjectSchema } from './ProductCustomFieldScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductCustomFieldCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpsertWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpsertWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProductCustomFieldCreateManyProductInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpdateWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpdateWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpdateManyWithWhereWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpdateManyWithWhereWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductCustomFieldScalarWhereInputObjectSchema),
          z.lazy(() => ProductCustomFieldScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInputObjectSchema =
  Schema
