import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NullableEnumUseTimeFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => UseTimeSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumUseTimeFieldUpdateOperationsInputObjectSchema = Schema
