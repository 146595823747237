import { z } from 'zod'
import { CustomFieldUpdateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUpdateWithoutProductCustomFieldInput.schema'
import { CustomFieldUncheckedUpdateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUncheckedUpdateWithoutProductCustomFieldInput.schema'
import { CustomFieldCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldCreateWithoutProductCustomFieldInput.schema'
import { CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUncheckedCreateWithoutProductCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldUpsertWithoutProductCustomFieldInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => CustomFieldUpdateWithoutProductCustomFieldInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedUpdateWithoutProductCustomFieldInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CustomFieldCreateWithoutProductCustomFieldInputObjectSchema,
        ),
        z.lazy(
          () =>
            CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CustomFieldUpsertWithoutProductCustomFieldInputObjectSchema =
  Schema
