import { z } from 'zod'
import { CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedCreateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldCreateOrConnectWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateOrConnectWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUpsertWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUpsertWithoutVariantCustomFieldsInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './CustomFieldWhereUniqueInput.schema'
import { CustomFieldUpdateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUpdateWithoutVariantCustomFieldsInput.schema'
import { CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldUpdateOneRequiredWithoutVariantCustomFieldsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CustomFieldCreateWithoutVariantCustomFieldsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CustomFieldUncheckedCreateWithoutVariantCustomFieldsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CustomFieldCreateOrConnectWithoutVariantCustomFieldsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => CustomFieldUpsertWithoutVariantCustomFieldsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CustomFieldWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => CustomFieldUpdateWithoutVariantCustomFieldsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CustomFieldUncheckedUpdateWithoutVariantCustomFieldsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CustomFieldUpdateOneRequiredWithoutVariantCustomFieldsNestedInputObjectSchema =
  Schema
