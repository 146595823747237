import { z } from 'zod'
import { VariantOnCustomFieldCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldCreateWithoutFieldInput.schema'
import { VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUncheckedCreateWithoutFieldInput.schema'
import { VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema } from './VariantOnCustomFieldCreateOrConnectWithoutFieldInput.schema'
import { VariantOnCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUpsertWithWhereUniqueWithoutFieldInput.schema'
import { VariantOnCustomFieldCreateManyFieldInputEnvelopeObjectSchema } from './VariantOnCustomFieldCreateManyFieldInputEnvelope.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInput.schema'
import { VariantOnCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema } from './VariantOnCustomFieldUpdateManyWithWhereWithoutFieldInput.schema'
import { VariantOnCustomFieldScalarWhereInputObjectSchema } from './VariantOnCustomFieldScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateManyWithoutFieldNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantOnCustomFieldCreateWithoutFieldInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldCreateWithoutFieldInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantOnCustomFieldCreateManyFieldInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => VariantOnCustomFieldScalarWhereInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldUpdateManyWithoutFieldNestedInputObjectSchema =
  Schema
