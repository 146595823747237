import { z } from 'zod'
import { ProductCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateWithoutProductOnCustomFieldInput.schema'
import { ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema } from './ProductUncheckedCreateWithoutProductOnCustomFieldInput.schema'
import { ProductCreateOrConnectWithoutProductOnCustomFieldInputObjectSchema } from './ProductCreateOrConnectWithoutProductOnCustomFieldInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutProductOnCustomFieldInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductCreateWithoutProductOnCustomFieldInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedCreateWithoutProductOnCustomFieldInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductCreateOrConnectWithoutProductOnCustomFieldInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProductCreateNestedOneWithoutProductOnCustomFieldInputObjectSchema =
  Schema
