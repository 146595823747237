import { z } from 'zod'
import { DrugGroupSchema } from '../enums/DrugGroup.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NullableEnumDrugGroupFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => DrugGroupSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumDrugGroupFieldUpdateOperationsInputObjectSchema =
  Schema
