import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteCaseSummary, RelatedCaseSummaryModel } from "./index"

export const SuggestedProductItemOriginalModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  productVariantId: z.number().int(),
  amount: z.number().int(),
  usage: z.enum(['TAKE', 'APPLY', 'APPLY_ACNE', 'APPLY_DARK_SPOT', 'SPRAY', 'SHAMPOO', 'APPLY_AFFECTED_AREA_AND_LEAVE_FOR', 'EYE_DROPS', 'PUT_ON', 'CLEANSE_FACE', 'INSERT_VAGINA', 'APPLY_30_MIN_BEFORE_SUN', 'APPLY_PEA_SIZE', 'SHOWER', 'DISSOLVE_ROOM_TEMP_WATER', 'APPLY_LEAVE_FOR_AND_RINSE', 'SQUEEZE_IN_A_BRUSH', 'CLEAN', 'SLIGHTLY_DISSOLVED_WAIT_FOR_BUBBLE_THEN_DRINK', 'PUT_ON_HAIR', 'CLEAN_HIDDEN_SPOT', 'BRUSH', 'NA', 'PASTE', 'THROAT_SPRAY']).nullish(),
  useAmount: z.string().nullish(),
  useUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']).nullish(),
  useFrequency: z.enum(['ONCE_DAILY', 'ONCE_DAILY_AFTER_MEAL', 'ONCE_DAILY_BEFORE_MEAL', 'ONCE_DAILY_WITH_MEAL', 'TWICE_DAILY', 'TWICE_DAILY_AFTER_MEAL', 'TWICE_DAILY_BEFORE_MEAL', 'TWICE_DAILY_WITH_MEAL', 'THRICE_DAILY', 'THRICE_DAILY_AFTER_MEAL', 'THRICE_DAILY_BEFORE_MEAL', 'THRICE_DAILY_WITH_MEAL', 'FOUR_TIMES_DAILY', 'FOUR_TIMES_DAILY_AFTER_MEAL', 'FOUR_TIMES_DAILY_BEFORE_MEAL', 'FOUR_TIMES_DAILY_WITH_MEAL', 'FIVE_TIMES_DAILY', 'EVERY_OTHER_DAY', 'ONCE_A_WEEK', 'TWICE_A_WEEK', 'THRICE_A_WEEK', 'EVERY_4_TO_6_HR', 'EVERY_4_HR', 'EVERY_6_HR', 'EVERY_8_HR', 'EVERY_12_HR', 'EVERY_24_HR', 'NA']).nullish(),
  useTime: z.enum(['AS_NEED', 'MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME', 'MORNING_AND_EVENING', 'MORNING_AND_BEFORE_BEDTIME', 'NOON_AND_BEFORE_BEDTIME', 'MORNING_NOON_AND_EVENING', 'MORNING_NOON_EVENING_AND_BEFORE_BEDTIME', 'EVERY_MONDAY', 'EVERY_TUESDAY', 'EVERY_WEDNESDAY', 'EVERY_THURSDAY', 'EVERY_FRIDAY', 'EVERY_SATURDAY', 'EVERY_SUNDAY', 'TUESDAY_AND_THURSDAY', 'MONDAY_WEDNESDAY_AND_FRIDAY', 'ON_EVEN_DAY', 'ON_ODD_DAY', 'AT_6_10_14_18_22_HR', 'IMMEDIATELY', 'NA']).nullish(),
  useDuration: z.number().int().nullish(),
  useDurationUnit: z.enum(['DAY', 'CONTINUE']).nullish(),
  refillTime: z.number().int().nullish(),
  refillTimeUnit: z.enum(['DAY', 'WEEK', 'MONTH', 'YEAR']).nullish(),
  note: z.string().nullish(),
  caseSummaryId: z.number().int(),
  warehouse: jsonSchema,
  customFields: jsonSchema,
})

export interface CompleteSuggestedProductItemOriginal extends z.infer<typeof SuggestedProductItemOriginalModel> {
  caseSummary: CompleteCaseSummary
}

/**
 * RelatedSuggestedProductItemOriginalModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedSuggestedProductItemOriginalModel: z.ZodSchema<CompleteSuggestedProductItemOriginal> = z.lazy(() => SuggestedProductItemOriginalModel.extend({
  caseSummary: RelatedCaseSummaryModel,
}))
