import { z } from 'zod'
import { ProductCustomFieldCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateWithoutFieldInput.schema'
import { ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema } from './ProductCustomFieldUncheckedCreateWithoutFieldInput.schema'
import { ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema } from './ProductCustomFieldCreateOrConnectWithoutFieldInput.schema'
import { ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema } from './ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInput.schema'
import { ProductCustomFieldCreateManyFieldInputEnvelopeObjectSchema } from './ProductCustomFieldCreateManyFieldInputEnvelope.schema'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema } from './ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInput.schema'
import { ProductCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema } from './ProductCustomFieldUpdateManyWithWhereWithoutFieldInput.schema'
import { ProductCustomFieldScalarWhereInputObjectSchema } from './ProductCustomFieldScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUncheckedUpdateManyWithoutFieldNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema),
          z
            .lazy(() => ProductCustomFieldCreateWithoutFieldInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUncheckedCreateWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldCreateOrConnectWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpsertWithWhereUniqueWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCustomFieldCreateManyFieldInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCustomFieldWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpdateWithWhereUniqueWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductCustomFieldScalarWhereInputObjectSchema),
          z.lazy(() => ProductCustomFieldScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCustomFieldUncheckedUpdateManyWithoutFieldNestedInputObjectSchema =
  Schema
