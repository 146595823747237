import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    searchable: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    regNo: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    salesType: z.lazy(() => SortOrderSchema).optional(),
    drugGroup: z.lazy(() => SortOrderSchema).optional(),
    drugType: z.lazy(() => SortOrderSchema).optional(),
    genericSearch: z.lazy(() => SortOrderSchema).optional(),
    useAmount: z.lazy(() => SortOrderSchema).optional(),
    pharmacyNote: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deletedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    auxiliaryLabel: z.lazy(() => SortOrderSchema).optional(),
    usage: z.lazy(() => SortOrderSchema).optional(),
    useUnit: z.lazy(() => SortOrderSchema).optional(),
    useFrequency: z.lazy(() => SortOrderSchema).optional(),
    useTime: z.lazy(() => SortOrderSchema).optional(),
    ingredient: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const ProductMinOrderByAggregateInputObjectSchema = Schema
