import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductCustomFieldCountOrderByAggregateInputObjectSchema } from './ProductCustomFieldCountOrderByAggregateInput.schema'
import { ProductCustomFieldAvgOrderByAggregateInputObjectSchema } from './ProductCustomFieldAvgOrderByAggregateInput.schema'
import { ProductCustomFieldMaxOrderByAggregateInputObjectSchema } from './ProductCustomFieldMaxOrderByAggregateInput.schema'
import { ProductCustomFieldMinOrderByAggregateInputObjectSchema } from './ProductCustomFieldMinOrderByAggregateInput.schema'
import { ProductCustomFieldSumOrderByAggregateInputObjectSchema } from './ProductCustomFieldSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      productId: z.lazy(() => SortOrderSchema).optional(),
      fieldId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => ProductCustomFieldCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => ProductCustomFieldAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ProductCustomFieldMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ProductCustomFieldMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ProductCustomFieldSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProductCustomFieldOrderByWithAggregationInputObjectSchema = Schema
