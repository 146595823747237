import { z } from 'zod'
import { VariantOnCustomFieldCreateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldCreateWithoutVariantInput.schema'
import { VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUncheckedCreateWithoutVariantInput.schema'
import { VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema } from './VariantOnCustomFieldCreateOrConnectWithoutVariantInput.schema'
import { VariantOnCustomFieldCreateManyVariantInputEnvelopeObjectSchema } from './VariantOnCustomFieldCreateManyVariantInputEnvelope.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUncheckedCreateNestedManyWithoutVariantInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => VariantOnCustomFieldCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => VariantOnCustomFieldCreateWithoutVariantInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantOnCustomFieldCreateManyVariantInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldUncheckedCreateNestedManyWithoutVariantInputObjectSchema =
  Schema
