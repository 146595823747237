import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumCustomFieldTypeFilterObjectSchema } from './EnumCustomFieldTypeFilter.schema'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { ProductCustomFieldListRelationFilterObjectSchema } from './ProductCustomFieldListRelationFilter.schema'
import { VariantOnCustomFieldListRelationFilterObjectSchema } from './VariantOnCustomFieldListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CustomFieldWhereInputObjectSchema),
        z.lazy(() => CustomFieldWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CustomFieldWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CustomFieldWhereInputObjectSchema),
        z.lazy(() => CustomFieldWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    fieldType: z
      .union([
        z.lazy(() => EnumCustomFieldTypeFilterObjectSchema),
        z.lazy(() => CustomFieldTypeSchema),
      ])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    fieldOptionsTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    searchField: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    productCustomField: z
      .lazy(() => ProductCustomFieldListRelationFilterObjectSchema)
      .optional(),
    variantCustomFields: z
      .lazy(() => VariantOnCustomFieldListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const CustomFieldWhereInputObjectSchema = Schema
