import { z } from 'zod'
import { SuggestedProductItemModel } from '../teleconsults'
import {
  CustomFieldCoerceDateModel,
  CustomFieldInputModel,
  CustomFieldStringDateModel,
} from './productCustomField.model'

export const SuggestedProductCustomFieldInputSchema = z.object({
  productUsage: CustomFieldInputModel,
  productUsageUnit: CustomFieldInputModel,
  productUsageFrequency: CustomFieldInputModel,
})
export type CustomFieldInput = z.infer<typeof CustomFieldInputModel>
export type SuggestedProductCustomFieldInput = z.infer<
  typeof SuggestedProductCustomFieldInputSchema
>

export const SuggestedProductCustomFieldSchema = z.object({
  productUsage: CustomFieldCoerceDateModel.nullable(),
  productUsageUnit: CustomFieldCoerceDateModel.nullable(),
  productUsageFrequency: CustomFieldCoerceDateModel.nullable(),
})

export const SuggestedProductItemWithCustomFieldSchema =
  SuggestedProductItemModel.omit({
    usage: true,
    useUnit: true,
    useTime: true,
    useFrequency: true,
  }).extend({
    customFields: SuggestedProductCustomFieldSchema,
  })

export const SuggestedProductItemWithCustomFieldInputSchema =
  SuggestedProductItemModel.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    caseSummaryId: true,
    warehouse: true,
    usage: true,
    useUnit: true,
    useTime: true,
    useFrequency: true,
  }).extend({
    customFields: z.object({
      productUsage: CustomFieldStringDateModel,
      productUsageUnit: CustomFieldStringDateModel,
      productUsageFrequency: CustomFieldStringDateModel,
    }),
    warehouse: z.array(z.string()).default([]),
  })
