import { z } from 'zod'
import { CustomFieldTypeSchema } from '../generated/trpc/schemas'
import { VariantModel } from '../generated/zod'
import { PictureSchema, ProductSchema } from './product.model'
import { ProductBulkImportSchema } from './productBulkImport.model'

export const ValidateProductSKUImportCustomFieldType = z.object({
  [CustomFieldTypeSchema.Enum.VARIANT_SELLING_UNIT]: z.string().optional(),
  [CustomFieldTypeSchema.Enum.VARIANT_UNIT]: z.string().optional(),
})
export const ProductValidateImportCustomFieldType = z.object({
  [CustomFieldTypeSchema.Enum.AUXILIARY_LABEL]: z.string().optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_FORMS]: z.array(z.string()).optional(),
  [CustomFieldTypeSchema.Enum.SKIN_TYPE]: z.array(z.string()).optional(),
  [CustomFieldTypeSchema.Enum.CONCERNS]: z.array(z.string()).optional(),
  [CustomFieldTypeSchema.Enum.ATTRIBUTES]: z.array(z.string()).optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE]: z.string().optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE_UNIT]: z.string().optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE_FREQUENCY]: z.string().optional(),
  [CustomFieldTypeSchema.Enum.DRUG_CLASSIFICATION]: z.string().optional(),
})

export const ValidateImportCustomFieldType =
  ValidateProductSKUImportCustomFieldType.merge(
    ProductValidateImportCustomFieldType,
  )

export const ProductValidateImportSKUSchema =
  ValidateProductSKUImportCustomFieldType.extend({
    picture: z.string(),
    warehouse: z.string(),
  }).merge(
    VariantModel.pick({
      sku: true,
      descriptionTr: true,
      batchAndExpireDate: true,
      size: true,
      cost: true,
      tagPrice: true,
      sellingPrice: true,
      canExpress: true,
    }),
  )

export const ProductValidateImportDataSchema =
  ProductValidateImportCustomFieldType.extend({
    brand: z.string(),
    subCategory: z.string(),
    productImage: z.string().optional(),
    variants: z.array(ProductValidateImportSKUSchema),
  }).merge(
    ProductSchema.pick({
      nameTr: true,
      regNo: true,
      type: true,
      salesType: true,
      drugGroup: true,
      drugType: true,
      propertyTr: true,
      descriptionTr: true,
      sideEffectTr: true,
      cautionTr: true,
      genericTr: true,
      useAmount: true,
      instructionTr: true,
      pharmacyNote: true,
    }),
  )

export const ErrorResponseSchema = z.object({
  index: z.number(),
  message: z.string(),
  column: z.string(),
})

export const ProductValidateImportSchema = z.object({
  data: z.array(ProductValidateImportDataSchema).min(1).max(100),
  pictures: z.array(PictureSchema).min(1).max(200),
})

export const ProductValidateImportOKResponseSchema = z.object({
  ok: z.literal(true),
  data: z.array(ProductBulkImportSchema),
})
export const ProductValidateImportErrorResponseSchema = z.object({
  ok: z.literal(false),
  data: z.array(ErrorResponseSchema),
})

export const ProductValidateImportResponseSchema = z.union([
  ProductValidateImportOKResponseSchema,
  ProductValidateImportErrorResponseSchema,
])

export type ProductValidateImportErrorResponse = z.infer<
  typeof ProductValidateImportErrorResponseSchema
>
export type ProductValidateImportOKResponse = z.infer<
  typeof ProductValidateImportOKResponseSchema
>
