import { z } from 'zod'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumCustomFieldTypeFilter> = z
  .object({
    equals: z.lazy(() => CustomFieldTypeSchema).optional(),
    in: z
      .lazy(() => CustomFieldTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CustomFieldTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CustomFieldTypeSchema),
        z.lazy(() => NestedEnumCustomFieldTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumCustomFieldTypeFilterObjectSchema = Schema
