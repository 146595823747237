import { z } from 'zod'
import { ProductCustomFieldWhereUniqueInputObjectSchema } from './objects/ProductCustomFieldWhereUniqueInput.schema'
import { ProductCustomFieldCreateInputObjectSchema } from './objects/ProductCustomFieldCreateInput.schema'
import { ProductCustomFieldUncheckedCreateInputObjectSchema } from './objects/ProductCustomFieldUncheckedCreateInput.schema'
import { ProductCustomFieldUpdateInputObjectSchema } from './objects/ProductCustomFieldUpdateInput.schema'
import { ProductCustomFieldUncheckedUpdateInputObjectSchema } from './objects/ProductCustomFieldUncheckedUpdateInput.schema'

export const ProductCustomFieldUpsertSchema = z.object({
  where: ProductCustomFieldWhereUniqueInputObjectSchema,
  create: z.union([
    ProductCustomFieldCreateInputObjectSchema,
    ProductCustomFieldUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductCustomFieldUpdateInputObjectSchema,
    ProductCustomFieldUncheckedUpdateInputObjectSchema,
  ]),
})
