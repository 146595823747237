import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SuggestedProductItemCountOrderByAggregateInputObjectSchema } from './SuggestedProductItemCountOrderByAggregateInput.schema'
import { SuggestedProductItemAvgOrderByAggregateInputObjectSchema } from './SuggestedProductItemAvgOrderByAggregateInput.schema'
import { SuggestedProductItemMaxOrderByAggregateInputObjectSchema } from './SuggestedProductItemMaxOrderByAggregateInput.schema'
import { SuggestedProductItemMinOrderByAggregateInputObjectSchema } from './SuggestedProductItemMinOrderByAggregateInput.schema'
import { SuggestedProductItemSumOrderByAggregateInputObjectSchema } from './SuggestedProductItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
      productVariantId: z.lazy(() => SortOrderSchema).optional(),
      amount: z.lazy(() => SortOrderSchema).optional(),
      usage: z.lazy(() => SortOrderSchema).optional(),
      useAmount: z.lazy(() => SortOrderSchema).optional(),
      useUnit: z.lazy(() => SortOrderSchema).optional(),
      useFrequency: z.lazy(() => SortOrderSchema).optional(),
      useTime: z.lazy(() => SortOrderSchema).optional(),
      useDuration: z.lazy(() => SortOrderSchema).optional(),
      useDurationUnit: z.lazy(() => SortOrderSchema).optional(),
      refillTime: z.lazy(() => SortOrderSchema).optional(),
      refillTimeUnit: z.lazy(() => SortOrderSchema).optional(),
      note: z.lazy(() => SortOrderSchema).optional(),
      warehouse: z.lazy(() => SortOrderSchema).optional(),
      customFields: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => SuggestedProductItemCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => SuggestedProductItemAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => SuggestedProductItemMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => SuggestedProductItemMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => SuggestedProductItemSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const SuggestedProductItemOrderByWithAggregationInputObjectSchema =
  Schema
