import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductOrderByWithRelationInputObjectSchema } from './ProductOrderByWithRelationInput.schema'
import { VariantWarehouseOrderByRelationAggregateInputObjectSchema } from './VariantWarehouseOrderByRelationAggregateInput.schema'
import { FlashsaleItemOrderByRelationAggregateInputObjectSchema } from './FlashsaleItemOrderByRelationAggregateInput.schema'
import { VariantPictureOrderByRelationAggregateInputObjectSchema } from './VariantPictureOrderByRelationAggregateInput.schema'
import { ProductReviewOrderByRelationAggregateInputObjectSchema } from './ProductReviewOrderByRelationAggregateInput.schema'
import { VariantOnCustomFieldOrderByRelationAggregateInputObjectSchema } from './VariantOnCustomFieldOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    sku: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    labelTr: z.lazy(() => SortOrderSchema).optional(),
    batchAndExpireDate: z.lazy(() => SortOrderSchema).optional(),
    size: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    inventory: z.lazy(() => SortOrderSchema).optional(),
    canExpress: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    inventoryUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
    unit: z.lazy(() => SortOrderSchema).optional(),
    sellingUnit: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    product: z
      .lazy(() => ProductOrderByWithRelationInputObjectSchema)
      .optional(),
    variantWarehouses: z
      .lazy(() => VariantWarehouseOrderByRelationAggregateInputObjectSchema)
      .optional(),
    flashsaleItem: z
      .lazy(() => FlashsaleItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(() => VariantPictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productReviews: z
      .lazy(() => ProductReviewOrderByRelationAggregateInputObjectSchema)
      .optional(),
    variantOnCustomFields: z
      .lazy(() => VariantOnCustomFieldOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const VariantOrderByWithRelationInputObjectSchema = Schema
