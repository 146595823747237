import { z } from 'zod'
import { VariantOnCustomFieldOrderByWithRelationInputObjectSchema } from './objects/VariantOnCustomFieldOrderByWithRelationInput.schema'
import { VariantOnCustomFieldWhereInputObjectSchema } from './objects/VariantOnCustomFieldWhereInput.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './objects/VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldCountAggregateInputObjectSchema } from './objects/VariantOnCustomFieldCountAggregateInput.schema'
import { VariantOnCustomFieldMinAggregateInputObjectSchema } from './objects/VariantOnCustomFieldMinAggregateInput.schema'
import { VariantOnCustomFieldMaxAggregateInputObjectSchema } from './objects/VariantOnCustomFieldMaxAggregateInput.schema'
import { VariantOnCustomFieldAvgAggregateInputObjectSchema } from './objects/VariantOnCustomFieldAvgAggregateInput.schema'
import { VariantOnCustomFieldSumAggregateInputObjectSchema } from './objects/VariantOnCustomFieldSumAggregateInput.schema'

export const VariantOnCustomFieldAggregateSchema = z.object({
  orderBy: z
    .union([
      VariantOnCustomFieldOrderByWithRelationInputObjectSchema,
      VariantOnCustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantOnCustomFieldWhereInputObjectSchema.optional(),
  cursor: VariantOnCustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      VariantOnCustomFieldCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: VariantOnCustomFieldMinAggregateInputObjectSchema.optional(),
  _max: VariantOnCustomFieldMaxAggregateInputObjectSchema.optional(),
  _avg: VariantOnCustomFieldAvgAggregateInputObjectSchema.optional(),
  _sum: VariantOnCustomFieldSumAggregateInputObjectSchema.optional(),
})
