import { z } from 'zod'
import { ProductCustomFieldScalarWhereInputObjectSchema } from './ProductCustomFieldScalarWhereInput.schema'
import { ProductCustomFieldUpdateManyMutationInputObjectSchema } from './ProductCustomFieldUpdateManyMutationInput.schema'
import { ProductCustomFieldUncheckedUpdateManyWithoutProductCustomFieldInputObjectSchema } from './ProductCustomFieldUncheckedUpdateManyWithoutProductCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpdateManyWithWhereWithoutFieldInput> =
  z
    .object({
      where: z.lazy(() => ProductCustomFieldScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductCustomFieldUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductCustomFieldUncheckedUpdateManyWithoutProductCustomFieldInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCustomFieldUpdateManyWithWhereWithoutFieldInputObjectSchema =
  Schema
