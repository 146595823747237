import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { VariantWarehouseFindManySchema } from '../findManyVariantWarehouse.schema'
import { FlashsaleItemFindManySchema } from '../findManyFlashsaleItem.schema'
import { VariantPictureFindManySchema } from '../findManyVariantPicture.schema'
import { ProductReviewFindManySchema } from '../findManyProductReview.schema'
import { VariantOnCustomFieldFindManySchema } from '../findManyVariantOnCustomField.schema'
import { VariantCountOutputTypeArgsObjectSchema } from './VariantCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantSelect> = z
  .object({
    id: z.boolean().optional(),
    active: z.boolean().optional(),
    sku: z.boolean().optional(),
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    productId: z.boolean().optional(),
    labelTr: z.boolean().optional(),
    batchAndExpireDate: z.boolean().optional(),
    size: z.boolean().optional(),
    cost: z.boolean().optional(),
    tagPrice: z.boolean().optional(),
    sellingPrice: z.boolean().optional(),
    inventory: z.boolean().optional(),
    variantWarehouses: z
      .union([z.boolean(), z.lazy(() => VariantWarehouseFindManySchema)])
      .optional(),
    canExpress: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    v2DrugItemId: z.boolean().optional(),
    inventoryUpdatedAt: z.boolean().optional(),
    flashsaleItem: z
      .union([z.boolean(), z.lazy(() => FlashsaleItemFindManySchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => VariantPictureFindManySchema)])
      .optional(),
    productReviews: z
      .union([z.boolean(), z.lazy(() => ProductReviewFindManySchema)])
      .optional(),
    variantOnCustomFields: z
      .union([z.boolean(), z.lazy(() => VariantOnCustomFieldFindManySchema)])
      .optional(),
    unit: z.boolean().optional(),
    sellingUnit: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    rank: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => VariantCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const VariantSelectObjectSchema = Schema
