import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { DrugGroupSchema } from '../enums/DrugGroup.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProductCreateManyBrandInput> = z
  .object({
    id: z.number().optional(),
    active: z.boolean().optional(),
    searchable: z.boolean().optional(),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    searchField: z.string(),
    regNo: z.string(),
    type: z.lazy(() => ProductTypeSchema),
    priority: z.number().optional(),
    salesType: z.lazy(() => SalesTypeSchema),
    drugGroup: z
      .lazy(() => DrugGroupSchema)
      .optional()
      .nullable(),
    propertyTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    drugType: z
      .lazy(() => DrugTypeSchema)
      .optional()
      .nullable(),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    sideEffectTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    cautionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    genericTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    genericSearch: z.string(),
    useAmount: z.string().optional().nullable(),
    instructionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    pharmacyNoteTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    pharmacyNote: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    deletedAt: z.date().optional().nullable(),
    v2DrugItemId: z.number().optional().nullable(),
    auxiliaryLabel: z
      .lazy(() => AuxiliaryLabelSchema)
      .optional()
      .nullable(),
    productForms: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    skinType: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    concerns: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    attributes: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    usage: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
    useUnit: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    useFrequency: z
      .lazy(() => UseFrequencySchema)
      .optional()
      .nullable(),
    useTime: z
      .lazy(() => UseTimeSchema)
      .optional()
      .nullable(),
    ingredient: z.string().optional().nullable(),
  })
  .strict()

export const ProductCreateManyBrandInputObjectSchema = Schema
