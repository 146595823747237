import { z } from 'zod'
import { ProductCustomFieldWhereInputObjectSchema } from './ProductCustomFieldWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldListRelationFilter> = z
  .object({
    every: z.lazy(() => ProductCustomFieldWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProductCustomFieldWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProductCustomFieldWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductCustomFieldListRelationFilterObjectSchema = Schema
