import { z } from 'zod'
import { VariantCreateNestedOneWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateNestedOneWithoutVariantOnCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateWithoutFieldInput> = z
  .object({
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutVariantOnCustomFieldsInputObjectSchema,
    ),
  })
  .strict()

export const VariantOnCustomFieldCreateWithoutFieldInputObjectSchema = Schema
