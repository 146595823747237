import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { VariantRelationFilterObjectSchema } from './VariantRelationFilter.schema'
import { VariantWhereInputObjectSchema } from './VariantWhereInput.schema'
import { CustomFieldRelationFilterObjectSchema } from './CustomFieldRelationFilter.schema'
import { CustomFieldWhereInputObjectSchema } from './CustomFieldWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => VariantOnCustomFieldWhereInputObjectSchema),
        z.lazy(() => VariantOnCustomFieldWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => VariantOnCustomFieldWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => VariantOnCustomFieldWhereInputObjectSchema),
        z.lazy(() => VariantOnCustomFieldWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    fieldId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    variant: z
      .union([
        z.lazy(() => VariantRelationFilterObjectSchema),
        z.lazy(() => VariantWhereInputObjectSchema),
      ])
      .optional(),
    field: z
      .union([
        z.lazy(() => CustomFieldRelationFilterObjectSchema),
        z.lazy(() => CustomFieldWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const VariantOnCustomFieldWhereInputObjectSchema = Schema
