import { z } from 'zod'
import { ProductUpdateOneRequiredWithoutProductOnCustomFieldNestedInputObjectSchema } from './ProductUpdateOneRequiredWithoutProductOnCustomFieldNestedInput.schema'
import { CustomFieldUpdateOneRequiredWithoutProductCustomFieldNestedInputObjectSchema } from './CustomFieldUpdateOneRequiredWithoutProductCustomFieldNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldUpdateInput> = z
  .object({
    product: z
      .lazy(
        () =>
          ProductUpdateOneRequiredWithoutProductOnCustomFieldNestedInputObjectSchema,
      )
      .optional(),
    field: z
      .lazy(
        () =>
          CustomFieldUpdateOneRequiredWithoutProductCustomFieldNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProductCustomFieldUpdateInputObjectSchema = Schema
