import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCountOrderByAggregateInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      variantId: z.lazy(() => SortOrderSchema).optional(),
      fieldId: z.lazy(() => SortOrderSchema).optional(),
    })
    .strict()

export const VariantOnCustomFieldCountOrderByAggregateInputObjectSchema = Schema
