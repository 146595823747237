import { z } from 'zod'
import { VariantOnCustomFieldCreateManyFieldInputObjectSchema } from './VariantOnCustomFieldCreateManyFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateManyFieldInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => VariantOnCustomFieldCreateManyFieldInputObjectSchema),
        z
          .lazy(() => VariantOnCustomFieldCreateManyFieldInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const VariantOnCustomFieldCreateManyFieldInputEnvelopeObjectSchema =
  Schema
