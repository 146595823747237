import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCountOutputTypeSelect> = z
  .object({
    variantWarehouses: z.boolean().optional(),
    flashsaleItem: z.boolean().optional(),
    pictures: z.boolean().optional(),
    productReviews: z.boolean().optional(),
    variantOnCustomFields: z.boolean().optional(),
  })
  .strict()

export const VariantCountOutputTypeSelectObjectSchema = Schema
