import { z } from 'zod'
import { VariantOnCustomFieldUpdateInputObjectSchema } from './objects/VariantOnCustomFieldUpdateInput.schema'
import { VariantOnCustomFieldUncheckedUpdateInputObjectSchema } from './objects/VariantOnCustomFieldUncheckedUpdateInput.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './objects/VariantOnCustomFieldWhereUniqueInput.schema'

export const VariantOnCustomFieldUpdateOneSchema = z.object({
  data: z.union([
    VariantOnCustomFieldUpdateInputObjectSchema,
    VariantOnCustomFieldUncheckedUpdateInputObjectSchema,
  ]),
  where: VariantOnCustomFieldWhereUniqueInputObjectSchema,
})
