import { z } from 'zod'
import { VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateWithoutVariantOnCustomFieldsInput.schema'
import { VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUncheckedCreateWithoutVariantOnCustomFieldsInput.schema'
import { VariantCreateOrConnectWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantCreateOrConnectWithoutVariantOnCustomFieldsInput.schema'
import { VariantUpsertWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUpsertWithoutVariantOnCustomFieldsInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantUpdateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUpdateWithoutVariantOnCustomFieldsInput.schema'
import { VariantUncheckedUpdateWithoutVariantOnCustomFieldsInputObjectSchema } from './VariantUncheckedUpdateWithoutVariantOnCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpdateOneRequiredWithoutVariantOnCustomFieldsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => VariantCreateWithoutVariantOnCustomFieldsInputObjectSchema,
          ),
          z.lazy(
            () =>
              VariantUncheckedCreateWithoutVariantOnCustomFieldsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            VariantCreateOrConnectWithoutVariantOnCustomFieldsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => VariantUpsertWithoutVariantOnCustomFieldsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => VariantUpdateWithoutVariantOnCustomFieldsInputObjectSchema,
          ),
          z.lazy(
            () =>
              VariantUncheckedUpdateWithoutVariantOnCustomFieldsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const VariantUpdateOneRequiredWithoutVariantOnCustomFieldsNestedInputObjectSchema =
  Schema
