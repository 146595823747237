import { z } from 'zod'
import { VariantOnCustomFieldCreateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldCreateWithoutVariantInput.schema'
import { VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUncheckedCreateWithoutVariantInput.schema'
import { VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema } from './VariantOnCustomFieldCreateOrConnectWithoutVariantInput.schema'
import { VariantOnCustomFieldUpsertWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUpsertWithWhereUniqueWithoutVariantInput.schema'
import { VariantOnCustomFieldCreateManyVariantInputEnvelopeObjectSchema } from './VariantOnCustomFieldCreateManyVariantInputEnvelope.schema'
import { VariantOnCustomFieldWhereUniqueInputObjectSchema } from './VariantOnCustomFieldWhereUniqueInput.schema'
import { VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInput.schema'
import { VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInputObjectSchema } from './VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInput.schema'
import { VariantOnCustomFieldScalarWhereInputObjectSchema } from './VariantOnCustomFieldScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldUpdateManyWithoutVariantNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => VariantOnCustomFieldCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => VariantOnCustomFieldCreateWithoutVariantInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantOnCustomFieldCreateManyVariantInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantOnCustomFieldUpdateManyWithWhereWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => VariantOnCustomFieldScalarWhereInputObjectSchema),
          z
            .lazy(() => VariantOnCustomFieldScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const VariantOnCustomFieldUpdateManyWithoutVariantNestedInputObjectSchema =
  Schema
