import { z } from 'zod'
import { CustomFieldOrderByWithRelationInputObjectSchema } from './objects/CustomFieldOrderByWithRelationInput.schema'
import { CustomFieldWhereInputObjectSchema } from './objects/CustomFieldWhereInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './objects/CustomFieldWhereUniqueInput.schema'
import { CustomFieldScalarFieldEnumSchema } from './enums/CustomFieldScalarFieldEnum.schema'

export const CustomFieldFindFirstSchema = z.object({
  orderBy: z
    .union([
      CustomFieldOrderByWithRelationInputObjectSchema,
      CustomFieldOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CustomFieldWhereInputObjectSchema.optional(),
  cursor: CustomFieldWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CustomFieldScalarFieldEnumSchema).optional(),
})
