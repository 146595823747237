import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { CustomFieldArgsObjectSchema } from './CustomFieldArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCustomFieldInclude> = z
  .object({
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    field: z
      .union([z.boolean(), z.lazy(() => CustomFieldArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProductCustomFieldIncludeObjectSchema = Schema
