import { z } from 'zod'
import { CustomFieldTypeSchema } from '../generated/trpc/schemas'
import { VariantModel } from '../generated/zod'
import { ProductSchema } from './product.model'

const importPictureSchema = z.object({
  rank: z.number().min(1),
  url: z.string().min(1),
})
export const CustomFieldId = z.object({
  fieldId: z.number(),
})
export const ProductSKUBulkImportCustomFieldType = z.object({
  [CustomFieldTypeSchema.Enum.VARIANT_SELLING_UNIT]: CustomFieldId.optional(),
  [CustomFieldTypeSchema.Enum.VARIANT_UNIT]: CustomFieldId.optional(),
})
export const ProductBulkImportCustomFieldType = z.object({
  [CustomFieldTypeSchema.Enum.AUXILIARY_LABEL]: CustomFieldId.optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_FORMS]: z.array(CustomFieldId).optional(),
  [CustomFieldTypeSchema.Enum.SKIN_TYPE]: z.array(CustomFieldId).optional(),
  [CustomFieldTypeSchema.Enum.CONCERNS]: z.array(CustomFieldId).optional(),
  [CustomFieldTypeSchema.Enum.ATTRIBUTES]: z.array(CustomFieldId).optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE]: CustomFieldId.optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE_UNIT]: CustomFieldId.optional(),
  [CustomFieldTypeSchema.Enum.PRODUCT_USAGE_FREQUENCY]:
    CustomFieldId.optional(),
  [CustomFieldTypeSchema.Enum.DRUG_CLASSIFICATION]: CustomFieldId.optional(),
})

const ArrayNumber = z.array(z.number())

export const ProductBulkImportSKUSchema = VariantModel.pick({
  sku: true,
  descriptionTr: true,
  batchAndExpireDate: true,
  size: true,
  cost: true,
  tagPrice: true,
  sellingPrice: true,
  canExpress: true,
}).extend({
  picture: importPictureSchema,
  warehouseIds: ArrayNumber,
  variantCustomFieldIds: z.array(CustomFieldId),
})

export const ProductBulkImportSchema = ProductSchema.pick({
  nameTr: true,
  regNo: true,
  type: true,
  salesType: true,
  drugGroup: true,
  drugType: true,
  propertyTr: true,
  descriptionTr: true,
  sideEffectTr: true,
  cautionTr: true,
  genericTr: true,
  useAmount: true,
  instructionTr: true,
  pharmacyNote: true,
}).extend({
  brandId: z.number(),
  subCategoryIds: ArrayNumber,
  productImage: importPictureSchema,
  variants: z.array(ProductBulkImportSKUSchema),
  productCustomFieldIds: z.array(CustomFieldId),
})

export type ProductBulkImport = z.infer<typeof ProductBulkImportSchema>
