import { z } from 'zod'
import { DrugGroupSchema } from '../enums/DrugGroup.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumDrugGroupNullableFilterObjectSchema } from './NestedEnumDrugGroupNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumDrugGroupNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => DrugGroupSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => DrugGroupSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => DrugGroupSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => DrugGroupSchema),
          z.lazy(
            () => NestedEnumDrugGroupNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumDrugGroupNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumDrugGroupNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumDrugGroupNullableWithAggregatesFilterObjectSchema =
  Schema
