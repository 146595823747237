import { z } from 'zod'
import { ProductCustomFieldFindManySchema } from '../findManyProductCustomField.schema'
import { VariantOnCustomFieldFindManySchema } from '../findManyVariantOnCustomField.schema'
import { CustomFieldCountOutputTypeArgsObjectSchema } from './CustomFieldCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    fieldType: z.boolean().optional(),
    priority: z.boolean().optional(),
    fieldOptionsTr: z.boolean().optional(),
    active: z.boolean().optional(),
    searchField: z.boolean().optional(),
    productCustomField: z
      .union([z.boolean(), z.lazy(() => ProductCustomFieldFindManySchema)])
      .optional(),
    variantCustomFields: z
      .union([z.boolean(), z.lazy(() => VariantOnCustomFieldFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CustomFieldCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CustomFieldSelectObjectSchema = Schema
