import { z } from 'zod'
import { VariantOnCustomFieldVariantIdFieldIdCompoundUniqueInputObjectSchema } from './VariantOnCustomFieldVariantIdFieldIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    variantId_fieldId: z
      .lazy(
        () =>
          VariantOnCustomFieldVariantIdFieldIdCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantOnCustomFieldWhereUniqueInputObjectSchema = Schema
