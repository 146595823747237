import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteProductCustomField, RelatedProductCustomFieldModel, CompleteVariantOnCustomField, RelatedVariantOnCustomFieldModel } from "./index"

export const CustomFieldModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fieldType: z.enum(['AUXILIARY_LABEL', 'DRUG_CLASSIFICATION', 'PRODUCT_FORMS', 'SKIN_TYPE', 'CONCERNS', 'ATTRIBUTES', 'PRODUCT_USAGE', 'PRODUCT_USAGE_UNIT', 'PRODUCT_USAGE_FREQUENCY', 'VARIANT_UNIT', 'VARIANT_SELLING_UNIT']),
  priority: z.number().int(),
  fieldOptionsTr: z.object({ en: z.string(), th: z.string() }),
  active: z.boolean(),
  searchField: z.string(),
})

export const CustomFieldModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fieldType: z.enum(['AUXILIARY_LABEL', 'DRUG_CLASSIFICATION', 'PRODUCT_FORMS', 'SKIN_TYPE', 'CONCERNS', 'ATTRIBUTES', 'PRODUCT_USAGE', 'PRODUCT_USAGE_UNIT', 'PRODUCT_USAGE_FREQUENCY', 'VARIANT_UNIT', 'VARIANT_SELLING_UNIT']),
  priority: z.number().int(),
  fieldOptionsTr: jsonSchema,
  active: z.boolean(),
  searchField: z.string(),
})

export interface CompleteCustomField extends z.infer<typeof CustomFieldModel> {
  productCustomField: CompleteProductCustomField[]
  variantCustomFields: CompleteVariantOnCustomField[]
}

/**
 * RelatedCustomFieldModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCustomFieldModel: z.ZodSchema<CompleteCustomField> = z.lazy(() => CustomFieldModel.extend({
  productCustomField: RelatedProductCustomFieldModel.array(),
  variantCustomFields: RelatedVariantOnCustomFieldModel.array(),
}))
