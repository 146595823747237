import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
    productVariantId: z.lazy(() => SortOrderSchema).optional(),
    amount: z.lazy(() => SortOrderSchema).optional(),
    useDuration: z.lazy(() => SortOrderSchema).optional(),
    refillTime: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const SuggestedProductItemSumOrderByAggregateInputObjectSchema = Schema
