import { z } from 'zod'
import { CustomFieldCreateNestedOneWithoutVariantCustomFieldsInputObjectSchema } from './CustomFieldCreateNestedOneWithoutVariantCustomFieldsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOnCustomFieldCreateWithoutVariantInput> =
  z
    .object({
      field: z.lazy(
        () =>
          CustomFieldCreateNestedOneWithoutVariantCustomFieldsInputObjectSchema,
      ),
    })
    .strict()

export const VariantOnCustomFieldCreateWithoutVariantInputObjectSchema = Schema
