import { z } from 'zod'
import { CustomFieldCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldCreateWithoutProductCustomFieldInput.schema'
import { CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUncheckedCreateWithoutProductCustomFieldInput.schema'
import { CustomFieldCreateOrConnectWithoutProductCustomFieldInputObjectSchema } from './CustomFieldCreateOrConnectWithoutProductCustomFieldInput.schema'
import { CustomFieldUpsertWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUpsertWithoutProductCustomFieldInput.schema'
import { CustomFieldWhereUniqueInputObjectSchema } from './CustomFieldWhereUniqueInput.schema'
import { CustomFieldUpdateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUpdateWithoutProductCustomFieldInput.schema'
import { CustomFieldUncheckedUpdateWithoutProductCustomFieldInputObjectSchema } from './CustomFieldUncheckedUpdateWithoutProductCustomFieldInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldUpdateOneRequiredWithoutProductCustomFieldNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CustomFieldCreateWithoutProductCustomFieldInputObjectSchema,
          ),
          z.lazy(
            () =>
              CustomFieldUncheckedCreateWithoutProductCustomFieldInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CustomFieldCreateOrConnectWithoutProductCustomFieldInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => CustomFieldUpsertWithoutProductCustomFieldInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CustomFieldWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => CustomFieldUpdateWithoutProductCustomFieldInputObjectSchema,
          ),
          z.lazy(
            () =>
              CustomFieldUncheckedUpdateWithoutProductCustomFieldInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CustomFieldUpdateOneRequiredWithoutProductCustomFieldNestedInputObjectSchema =
  Schema
